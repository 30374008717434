@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 400;
  src: url("../font/heebo-v3-hebrew_latin-regular.eot?#iefix") format("embedded-opentype"), url("../font/heebo-v3-hebrew_latin-regular.woff2") format("woff2"), url("../font/heebo-v3-hebrew_latin-regular.woff") format("woff"), url("../font/heebo-v3-hebrew_latin-regular.svg#Heebo") format("svg");
}

@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 700;
  src: url("../font/heebo-v3-hebrew_latin-700.eot?#iefix") format("embedded-opentype"), url("../font/heebo-v3-hebrew_latin-700.woff2") format("woff2"), url("../font/heebo-v3-hebrew_latin-700.woff") format("woff"), url("../font/heebo-v3-hebrew_latin-700.svg#Heebo") format("svg");
}

@font-face {
  font-family: "Heebo";
  font-style: normal;
  font-weight: 900;
  src: url("../font/heebo-v3-hebrew_latin-900.eot?#iefix") format("embedded-opentype"), url("../font/heebo-v3-hebrew_latin-900.woff2") format("woff2"), url("../font/heebo-v3-hebrew_latin-900.woff") format("woff"), url("../font/heebo-v3-hebrew_latin-900.svg#Heebo") format("svg");
}

html {
  font-size: 19px;
  font-size: 0.98958vw;
  -webkit-text-size-adjust: 100%;
  background: #fff;
  height: 100%;
}

@media (max-width: 1500px) {
  html {
    font-size: 22px;
    font-size: 1.14583vw;
  }
}

@media (max-width: 1023px) {
  html {
    font-size: 19px;
    font-size: 2.96875vw;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 19px;
  }
}

body {
  position: relative;
  font-family: "Heebo", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 19px;
  font-size: 0.98958vw;
  font-weight: 400;
  line-height: 1.31579;
  letter-spacing: -0.06em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #103f79;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
  height: 100%;
  background: #f6faff;
  max-width: 2100px;
  margin-left: auto;
  margin-right: auto;
  min-width: 320px;
}

[dir='ltr'] body {
  letter-spacing: 0;
}

@media (min-width: 1920px) {
  body {
    font-size: 19px;
  }
}

@media (max-width: 1500px) {
  body {
    font-size: 22px;
    font-size: 1.14583vw;
  }
}

@media (max-width: 1023px) {
  body {
    font-size: 19px;
    font-size: 2.96875vw;
  }
}

@media (max-width: 319px) {
  body {
    overflow-x: auto;
  }
}

.notransition {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.touchevents body {
  cursor: pointer;
}

.touchevents .touch-focused {
  cursor: pointer;
}

.no-touchevents .wrap ::-webkit-scrollbar {
  -webkit-appearance: none;
}

.no-touchevents .wrap ::-webkit-scrollbar:vertical {
  width: 0.31579rem;
}

.no-touchevents .wrap ::-webkit-scrollbar:horizontal {
  height: 0.31579rem;
}

.no-touchevents .wrap ::-webkit-scrollbar-thumb {
  background-color: rgba(136, 136, 136, 0.4) !important;
  border: none;
}

.no-touchevents .wrap ::-webkit-scrollbar-track {
  margin: 0;
  border: none;
  background-color: rgba(68, 68, 68, 0.1) !important;
}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.no-animation *, .no-animation *:before, .no-animation *:after {
  -webkit-animation: none !important;
          animation: none !important;
}

.no-transition *, .no-transition *:before, .no-transition *:after {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

::-moz-selection {
  background: #d1e6f5;
  color: #103f79;
  text-shadow: none;
}

::selection {
  background: #d1e6f5;
  color: #103f79;
  text-shadow: none;
}

/*
*
* Skeleton elements
*
*/
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.scrollable-x {
  overflow-y: hidden;
  overflow-x: auto;
}

.container {
  max-width: 80rem;
  padding: 0 1.57895rem;
  margin: 0 auto;
}

.container--thin {
  max-width: 61.05263rem;
}

.wrap {
  position: relative;
  float: left;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background: #f6faff;
  -webkit-box-shadow: 0 0 0 1px #e3e6eb;
          box-shadow: 0 0 0 1px #e3e6eb;
  z-index: 1;
}

.wrap:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 33.68421rem;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#a9bacf), to(#f6faff));
  background-image: -webkit-linear-gradient(top, #a9bacf, #f6faff);
  background-image: -o-linear-gradient(top, #a9bacf, #f6faff);
  background-image: linear-gradient(to bottom, #a9bacf, #f6faff);
  content: '';
}

.lte-ie9 .wrap:before {
  background: url("../img/wrap-bg.svg") center top repeat-x;
  background-size: cover;
}

/*

Responsive Helpers

 */
.respon-meter {
  position: absolute;
  z-index: 9999;
}

@media (max-width: 1023px) {
  .respon-meter {
    z-index: 1199;
  }
}

@media (max-width: 1023px) {
  .respon-meter {
    z-index: 1023;
  }
}

@media (max-width: 767px) {
  .respon-meter {
    z-index: 479;
  }
}

.tablet-show,
.tablet-show-inline-block,
.tablet-show-inline,
.tablet-show-table-row,
.tablet-show-table-cell {
  display: none !important;
}

@media (max-width: 1023px) {
  .tablet-hide {
    display: none !important;
  }
  .tablet-show {
    display: block !important;
  }
  .tablet-show-inline-block {
    display: inline-block !important;
  }
  .tablet-show-inline {
    display: inline !important;
  }
  .tablet-show-table-row {
    display: table-row !important;
  }
  .tablet-show-table-cell {
    display: table-cell !important;
  }
}

.tablet-small-show,
.tablet-small-show-inline-block,
.tablet-small-show-inline,
.tablet-small-show-table-row,
.tablet-small-show-table-cell {
  display: none !important;
}

@media (max-width: 1023px) {
  .tablet-small-hide {
    display: none !important;
  }
  .tablet-small-show {
    display: block !important;
  }
  .tablet-small-show-inline-block {
    display: inline-block !important;
  }
  .tablet-show-inline {
    display: inline !important;
  }
  .tablet-small-show-table-row {
    display: table-row !important;
  }
  .tablet-small-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-show,
.mobile-show-inline-block,
.mobile-show-inline,
.mobile-show-table-cell {
  display: none !important;
}

@media (max-width: 767px) {
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
  .mobile-show-inline-block {
    display: inline-block !important;
  }
  .mobile-show-inline {
    display: inline !important;
  }
  .mobile-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-small-show,
.mobile-small-show-inline-block,
.mobile-small-show-inline,
.mobile-small-show-table-cell {
  display: none !important;
}

@media (max-width: 767px) {
  .mobile-small-hide {
    display: none !important;
  }
  .mobile-small-show {
    display: block !important;
  }
  .mobile-small-show-inline-block {
    display: inline-block !important;
  }
  .mobile-small-show-inline {
    display: inline !important;
  }
  .mobile-small-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-xsmall-show,
.mobile-xsmall-show-inline-block,
.mobile-xsmall-show-inline,
.mobile-xsmall-show-table-cell {
  display: none !important;
}

@media (max-width: 479px) {
  .mobile-xsmall-hide {
    display: none !important;
  }
  .mobile-xsmall-show {
    display: block !important;
  }
  .mobile-xsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xsmall-show-inline {
    display: inline !important;
  }
  .mobile-xsmall-show-table-cell {
    display: table-cell !important;
  }
}

.mobile-xxsmall-show,
.mobile-xxsmall-show-inline-block,
.mobile-xxsmall-show-inline,
.mobile-xxsmall-show-table-cell {
  display: none !important;
}

@media (max-width: 359px) {
  .mobile-xxsmall-hide {
    display: none !important;
  }
  .mobile-xxsmall-show {
    display: block !important;
  }
  .mobile-xxsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xxsmall-show-inline {
    display: inline !important;
  }
  .mobile-xxsmall-show-table-cell {
    display: table-cell !important;
  }
}

a, .link {
  outline: none;
  text-decoration: underline;
  color: inherit;
  background-color: transparent;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover, .link:hover {
  color: #6dafe0;
  text-decoration: underline;
}

.link--default {
  color: #103f79;
}

.link--dashed {
  position: relative;
  color: inherit;
  border-bottom: 1px dashed;
  border-bottom-color: inherit;
  text-decoration: none;
}

.link--dashed:hover {
  text-decoration: none;
  border-bottom-color: inherit;
}

.link--bordered {
  color: inherit;
  border-bottom: 1px solid;
  border-bottom-color: inherit;
  text-decoration: none;
}

.link--bordered:hover {
  text-decoration: none;
  border-bottom-color: inherit;
}

sub, sup {
  font-size: 65%;
}

img {
  max-width: 100%;
}

img.fullwidth {
  display: block;
  width: 100%;
  height: auto;
}

strong {
  font-weight: 700;
}

h1, .h1 {
  font-size: 1.21053rem;
  font-weight: 700;
  line-height: 1.08696;
  margin: 1.84211rem 0 0;
}

h1:first-child, .h1:first-child {
  margin-top: 0;
}

h2, .h2 {
  font-size: 1.21053rem;
  font-weight: 700;
  line-height: 1.08696;
  margin: 1.84211rem 0 0;
}

h2:first-child, .h2:first-child {
  margin-top: 0;
}

h3, .h3 {
  font-size: 1.10526rem;
  font-weight: 700;
  line-height: 1.33333;
  margin: 1.84211rem 0 0;
}

h3:first-child, .h3:first-child {
  margin-top: 0;
}

h4, .h4 {
  font-size: 100%;
  font-weight: 700;
  line-height: 1.08696;
  margin: 1.84211rem 0 0;
}

h4:first-child, .h4:first-child {
  margin-top: 0;
}

.h-bg {
  display: inline-block;
  background-color: #103f79;
  color: #fff;
  padding: 0.63158rem 1.84211rem;
  margin: 0.52632rem 0;
}

@media (max-width: 1023px) {
  .h-bg {
    margin: 0;
  }
}

p {
  margin: 1.31579rem 0 0;
  -webkit-text-size-adjust: 100%;
}

p:first-child {
  margin-top: 0;
}

ol, ul {
  margin: 1.05263rem 0 0;
  padding: 0;
}

ol:first-child, ul:first-child {
  margin-top: 0;
}

ol li, ul li {
  margin: 0.63158rem 0 0;
  padding: 0 0 0 1.05263rem;
}

ol li:first-child, ul li:first-child {
  margin-top: 0;
}

li ul, li ol {
  margin-top: 0.63158rem;
}

li li {
  margin-top: 0.31579rem;
}

ul {
  list-style: none;
}

ul li {
  position: relative;
  text-indent: -1.05263rem;
}

ul li * {
  text-indent: 0;
}

ul li:before {
  position: relative;
  top: -0.10526rem;
  display: inline-block;
  vertical-align: middle;
  width: 0.31579rem;
  height: 0.31579rem;
  border-radius: 50%;
  margin-right: 0.73684rem;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: inherit;
  text-indent: 0;
  content: "";
}

nav ul {
  margin: 0;
  padding: 0;
}

nav li {
  margin: 0;
  padding: 0;
  text-indent: 0;
}

nav li:before {
  display: none;
}

table {
  width: 100%;
  margin-top: 2.36842rem;
}

table:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  table {
    margin-top: 1.84211rem;
  }
}

table caption {
  background: #6dafe0;
  color: #fff;
  text-align: initial;
  padding: 0.68421rem 1.05263rem;
}

table th, table td {
  padding: 0.26316rem 1.05263rem;
  text-align: initial;
}

table tbody tr:nth-child(odd) {
  background-color: #eaeef3;
}

.cell-fit {
  white-space: nowrap;
  width: 1%;
}

figure {
  position: relative;
  display: block;
  max-width: 100%;
  text-align: center;
  margin-top: 1.31579rem;
}

figure:first-child {
  margin-top: 0;
}

.figure-left {
  float: left;
  margin-right: 2.63158rem;
  max-width: 50%;
}

@media (max-width: 1023px) {
  .figure-left {
    float: none;
    max-width: 100%;
    margin-right: 0;
  }
}

.figure-right {
  float: right;
  margin-left: 2.63158rem;
  max-width: 50%;
}

@media (max-width: 1023px) {
  .figure-right {
    float: none;
    max-width: 100%;
    margin-left: 0;
  }
}

figcaption {
  position: absolute;
  right: 0;
  bottom: 2.10526rem;
  text-align: initial;
  background: #103f79;
  color: #fff;
  padding: 0.78947rem 1.31579rem;
  max-width: 100%;
}

[dir='ltr'] figcaption {
  right: auto;
  left: 0;
}

/*

Icons

*/
.icon {
  position: relative;
  display: inline-block;
  fill: currentColor;
  width: 1em;
  height: 1em;
  letter-spacing: 0;
  line-height: 1;
}

.icon--hover {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.icon--hover:hover {
  fill: #6dafe0;
}

.icon--in-text {
  top: -1px;
  vertical-align: middle;
  margin: 0 0.26316rem;
}

.icon--in-text:first-child {
  margin-left: 0;
}

.icon--in-text:last-child {
  margin-right: 0;
}

.icon--upload {
  font-size: 0.73684rem;
  width: 1.14286em;
}

.btn .icon--upload {
  top: -1px;
}

.icon--chevron-down {
  font-size: 0.31579rem;
  width: 1.33333em;
}

.icon--arrow-left {
  font-size: 0.94737rem;
  width: 1.38889em;
}

.icon--arrow-right {
  font-size: 0.94737rem;
  width: 1.38889em;
}

.icon--plus {
  font-size: 0.78947rem;
}

.icon--list {
  font-size: 0.68421rem;
  width: 1.30769em;
}

.icon--arrow-forward {
  font-size: 0.89474rem;
  width: 1.17647em;
}

.icon--trash {
  font-size: 1rem;
  width: 0.89474em;
}

.icon--tick {
  font-size: 0.42105rem;
  width: 1.25em;
}

.icon--cross-bold {
  font-size: 0.57895rem;
}

.icon--help {
  font-size: 0.78947rem;
}

.icon--sort-desc {
  font-size: 0.63158rem;
  width: 1.33333em;
}

.icon--chevrons-vert {
  font-size: 0.52632rem;
  width: 0.7em;
}

.icon--clip {
  font-size: 1.31579rem;
  width: 0.44em;
}

.icon--tags {
  font-size: 0.63158rem;
  width: 1.25em;
}

.icon--document {
  font-size: 0.57895rem;
  width: 0.81818em;
}

.icon--copy {
  font-size: 0.52632rem;
}

.icon--logout {
  font-size: 0.63158rem;
}

.icon--document-copy {
  font-size: 1.21053rem;
  width: 0.82609em;
}

.icon--eye {
  font-size: 0.78947rem;
}

.icon--flag {
  font-size: 0.68421rem;
}

.icon--link {
  font-size: 0.89474rem;
}

.icon--facebook {
  font-size: 1.10526rem;
  width: 0.52632em;
}

.icon--vkontakte {
  font-size: 0.73684rem;
  width: 1.76923em;
}

.icon--twitter {
  font-size: 0.84211rem;
  width: 1.2em;
}

.icon--google-plus {
  font-size: 0.89474rem;
  width: 1.35294em;
}

/*

Custom Scroll

*/
.nicescroll-rails {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  visibility: hidden;
  display: none;
  border-radius: 5.26316rem !important;
  background-color: transparent !important;
}

.nicescroll-rails:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 5.26316rem !important;
  background-color: rgba(68, 68, 68, 0.1) !important;
  content: "";
}

.nicescroll-rails--inited {
  visibility: visible;
}

.nicescroll-invisible {
  visibility: hidden;
}

.nicescroll-cursors {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  background-color: rgba(136, 136, 136, 0.4) !important;
  border-radius: 5.26316rem !important;
  z-index: 1;
}

.nicescroll-rails-vr {
  width: 0.31579rem !important;
  margin-left: -0.26316rem;
}

.nicescroll-rails-vr .nicescroll-cursors {
  width: 0.31579rem !important;
}

.nicescroll-rails-vr .nicescroll-cursors:before {
  position: absolute;
  top: -0.26316rem;
  right: -0.26316rem;
  bottom: -0.26316rem;
  left: -0.26316rem;
  content: "";
}

.nicescroll-rails-hr {
  height: 0.31579rem !important;
}

.nicescroll-rails-hr:after {
  position: absolute;
  top: -0.52632rem;
  right: 0;
  bottom: -0.52632rem;
  left: 0;
  content: "";
}

.nicescroll-rails-hr .nicescroll-cursors {
  height: 0.31579rem !important;
}

.nicescroll-rails-hr .nicescroll-cursors:before {
  position: absolute;
  top: -0.26316rem;
  right: -0.26316rem;
  bottom: -0.26316rem;
  left: -0.26316rem;
  content: "";
}

.nicescroll-rails-vr[data-padding-top="5"]:before {
  top: 0.26316rem;
}

.nicescroll-rails-vr[data-padding-bottom="5"]:before {
  bottom: 0.26316rem;
}

/*

Column Layout

*/
.cols {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.cols--auto {
  table-layout: auto;
}

.cols__inner {
  display: table-row;
}

.cols-wrapper {
  margin-left: -0.47368rem;
  margin-right: -0.47368rem;
}

.col {
  position: relative;
  display: table-cell;
  vertical-align: top;
  padding: 0 0.47368rem;
}

.cols--ultracompact .col {
  padding-left: 0;
  padding-right: 0;
}

.col--fit {
  width: 1px;
}

.col--1-4 {
  width: 25%;
}

.col--3-4 {
  width: 75%;
}

.col--1-3 {
  width: 33.33%;
}

.col--2-3 {
  width: 66.66%;
}

.col--1-2 {
  width: 50%;
}

.cols--floats {
  display: block;
  width: auto;
  margin-left: -0.52632rem;
  margin-right: -0.52632rem;
}

.cols--floats:after {
  content: "";
  display: table;
  clear: both;
}

.cols--floats > .cols__inner {
  display: block;
  float: left;
  width: 100%;
  margin-top: -0.52632rem;
  margin-bottom: -0.52632rem;
}

.cols--floats > .cols__inner > .col {
  float: left;
  display: block;
  padding: 0.52632rem;
}

.cols--floats > .cols__inner > .col--right {
  float: right;
}

/*

Expansible content

 */
.expand-it-container {
  overflow: hidden;
  max-height: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.expand-it-container.overflow-visible {
  overflow: visible;
}

.expand-it-container.active {
  max-height: 526.26316rem;
}

.text-expand {
  position: relative;
}

.text-expand__container {
  max-height: 6.63158rem;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.text-expand__inner {
  padding-bottom: 2.36842rem;
}

.text-expand__more {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.05263rem 0.52632rem 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), color-stop(40%, rgba(255, 255, 255, 0.8)), to(#fff));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 40%, #fff);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 40%, #fff);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 40%, #fff);
  text-align: center;
}

.text-expand__more-link {
  position: relative;
  display: inline-block;
  padding: 0.10526rem 0.52632rem;
  background-color: #fafafa;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05), 0 1px 0.52632rem white;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05), 0 1px 0.52632rem white;
  border-radius: 5.26316rem;
  border: 1px solid #e8e8e8;
  font-size: 0.68421rem;
  text-decoration: none;
  color: #000;
}

.text-expand__more-link:hover {
  color: #000;
  background-color: #f4f4f4;
}

.text-expand__more-link:active {
  color: #000;
  background-color: #f4f4f4;
}

.text-expand__more-tooltip {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border-radius: 5.26316rem;
}

/*

Form's layout

*/
.form {
  position: relative;
  margin-top: 1.31579rem;
  margin-left: auto;
  margin-right: auto;
}

.form:after {
  content: "";
  display: table;
  clear: both;
}

.form:first-child {
  margin-top: 0;
}

.form__items {
  margin-top: 1.31579rem;
}

.form__items:after {
  content: "";
  display: table;
  clear: both;
}

.form__items:first-child {
  margin-top: 0;
}

.form__item {
  position: relative;
  margin-top: 0.94737rem;
}

.form__item:after {
  content: "";
  display: table;
  clear: both;
}

.form__item:first-child {
  margin-top: 0;
}

.form__item--mm {
  margin-top: 2rem;
}

@media (max-width: 1023px) {
  .form__item--mm {
    margin-top: 1.47368rem;
  }
}

.form__item-label {
  font-size: 0.84211rem;
  font-weight: 500;
  margin-top: 0.52632rem;
}

.form__item-label:first-child {
  margin-top: 0;
}

.form__item-field {
  position: relative;
  margin-top: 0.78947rem;
}

.form__item-field:first-child {
  margin-top: 0;
}

.form__item-field--checkboxes {
  margin-top: 0.26316rem;
}

.form__item-tip {
  font-size: 0.63158rem;
  font-weight: 500;
  margin-top: 0.26316rem;
}

.form__item-tip:first-child {
  margin-top: 0;
}

.form__item-tip--small {
  font-size: 0.57895rem;
  font-weight: 400;
  line-height: 1.72727;
}

.form__item-tip .icon {
  position: relative;
  top: -1px;
  font-size: 0.68421rem;
  vertical-align: middle;
  margin-right: 0.26316rem;
}

.form__errors + .form__item,
.form__errors + .form__items {
  margin-top: 0.78947rem;
}

.form__actions {
  position: relative;
  margin-top: 3.15789rem;
}

.form__actions:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .form__actions {
    margin-top: 2.36842rem;
  }
}

.form__error {
  padding: 0;
  color: #FF6549;
  font-size: 0.68421rem;
  font-weight: 500;
  -webkit-animation: 0.5s ease-out;
          animation: 0.5s ease-out;
  -webkit-animation-iteration-count: 2;
          animation-iteration-count: 2;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.form__item-field--error-absolute .form__error {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
}

.form__error.active {
  -webkit-animation-name: pulsateError;
          animation-name: pulsateError;
}

.form__error .icon {
  position: relative;
  top: -1px;
  margin: 0 0.26316rem;
  font-size: 1.05263rem;
  vertical-align: middle;
}

.form__error .icon:first-child {
  margin-left: 0;
}

.form__error .icon:last-child {
  margin-right: 0;
}

@-webkit-keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

@keyframes pulsateError {
  0% {
    opacity: 1.0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1.0;
  }
}

/*

Textfields

*/
textarea,
input,
button {
  -webkit-font-smoothing: antialiased;
  outline: none;
  line-height: 1.25;
  font-family: "Heebo", Tahoma, Arial, Helvetica, sans-serif;
}

.textfield-wrapper {
  position: relative;
}

.textfield {
  display: inline-block;
  font-family: "Heebo", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 1.10526rem;
  font-weight: 700;
  line-height: 1.285714285714286;
  letter-spacing: -0.06em;
  background: #fff;
  color: #103f79;
  padding: 0.52632rem 1.05263rem 0.57895rem;
  height: 2.63158rem;
  width: 100%;
  border: 1px solid #103f79;
  -webkit-box-shadow: inset -0.21053rem 0.31579rem 0.26316rem rgba(0, 0, 0, 0.2);
          box-shadow: inset -0.21053rem 0.31579rem 0.26316rem rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-appearance: none;
  outline: none;
  resize: none;
  -webkit-transition: color 0.2s, background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, -webkit-box-shadow 0.2s;
  -o-transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s, box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

[dir='ltr'] .textfield {
  letter-spacing: 0;
}

.textfield:focus {
  border-color: #103f79;
  outline: none;
  -webkit-box-shadow: inset -0.21053rem 0.31579rem 0.52632rem rgba(0, 0, 0, 0.1);
          box-shadow: inset -0.21053rem 0.31579rem 0.52632rem rgba(0, 0, 0, 0.1);
}

.textfield:invalid {
  background-color: #fff;
}

.textfield:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1.57895rem #fff inset;
}

.textfield::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.textfield::-webkit-input-placeholder {
  color: #103f79;
  opacity: 1;
}

.textfield::-moz-placeholder {
  color: #103f79;
  opacity: 1;
}

.textfield:-moz-placeholder {
  color: #103f79;
  opacity: 1;
}

.textfield:-ms-input-placeholder {
  color: #103f79;
  opacity: 1;
}

.textfield.error {
  border-color: #FF6549;
  color: #FF6549;
}

.textfield[disabled], .textfield.disabled, .textfield[readonly] {
  opacity: 1;
  color: #97969B;
  background-color: #eee;
  border-color: transparent;
  cursor: default;
  cursor: not-allowed;
}

.textfield--autowidth {
  width: auto;
}

.textfield--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

textarea.textfield {
  padding: 0.78947rem 1.15789rem 0.78947rem;
  height: auto;
}

textarea.textfield--like-text {
  padding: 0;
}

.textfield-icon {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.57895rem;
  width: 1.84211rem;
  text-align: center;
  color: #C2C2C2;
  pointer-events: none;
}

.textfield-icon .icon {
  vertical-align: middle;
}

.textfield-icon + .textfield {
  padding-left: 1.84211rem;
}

/*

Buttons

*/
.btn {
  position: relative;
  display: inline-block;
  outline: none;
  text-decoration: none;
  text-align: center;
  font-family: "Heebo", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 1.10526rem;
  font-weight: 400;
  letter-spacing: -0.06em;
  border: none;
  padding: 0 1.05263rem;
  vertical-align: middle;
  white-space: nowrap;
  line-height: 2.63158rem;
  height: 2.63158rem;
  min-width: 2.63158rem;
  border-radius: 0;
  color: #fff !important;
  -webkit-appearance: none;
  background: #103f79;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
}

[dir='ltr'] .btn {
  letter-spacing: 0;
}

.btn::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}

.btn > .color-active {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.btn:hover {
  color: #fff !important;
  background-color: #6dafe0;
  text-decoration: none;
}

.btn:hover > .color-active {
  color: #fff;
}

.btn:active {
  text-decoration: none;
}

.btn[disabled], .btn.disabled {
  background-color: #ebebeb;
  color: #888 !important;
  border-color: inherit;
  border-color: currentColor;
  opacity: 1;
  cursor: default;
  cursor: not-allowed;
}

.btn + .btn {
  margin-left: 1.05263rem;
}

.btn + .btn--nomargin {
  margin-left: 0;
}

.btn > input[type='file'] {
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 6.31579rem;
  position: absolute;
  top: -1.05263rem;
  right: -0.52632rem;
  vertical-align: top;
  z-index: 3;
  cursor: pointer;
}

.btn--compact {
  padding-right: 1.05263rem;
  padding-left: 1.05263rem;
}

.btn--ultracompact {
  padding-right: 0;
  padding-left: 0;
}

.btn--pseudo {
  background: transparent !important;
  border-color: transparent !important;
  color: inherit !important;
}

.btn--pseudo:hover {
  color: inherit !important;
}

.btn--link {
  color: #6dafe0 !important;
}

.btn--no-hover:hover {
  color: inherit !important;
  background: inherit !important;
  border-color: inherit;
  border-color: currentColor;
}

.btn--fullwidth {
  width: 100%;
  margin-top: 0.52632rem;
}

.btn--fullwidth:first-child {
  margin-top: 0;
}

.btn--fullwidth + .btn {
  margin-left: 0;
}

.btn .icon {
  vertical-align: middle;
  margin-left: 0.15789rem;
  margin-right: 0.15789rem;
}

.btn .icon:first-child {
  margin-left: 0;
}

.btn .icon:last-child {
  margin-right: 0;
}

.ui-group {
  font-size: 0;
}

/*

Checkboxes

*/
.checkboxes {
  margin-right: -1.57895rem;
}

.checkboxes:after {
  content: "";
  display: table;
  clear: both;
}

.checkboxes__inner {
  float: left;
  width: 100%;
  margin-bottom: -0.52632rem;
}

.checkbox {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-right: 1.68421rem;
  margin-bottom: 0.52632rem;
  margin-right: 1.57895rem;
  font-size: 1rem;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

[dir='ltr'] .checkbox {
  padding-right: 0;
  padding-left: 1.68421rem;
}

.checkbox__icon {
  position: absolute;
  top: 0.10526rem;
  right: 0;
  width: 1.15789rem;
  height: 1.15789rem;
  background: #fff;
  -webkit-box-shadow: inset -0.10526rem 0.15789rem 0.15789rem rgba(0, 0, 0, 0.2);
          box-shadow: inset -0.10526rem 0.15789rem 0.15789rem rgba(0, 0, 0, 0.2);
  border: 1px solid #103f79;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  /* Separate media-queries for main resolutions to correct appearance */
}

[dir='ltr'] .checkbox__icon {
  right: auto;
  left: 0;
}

.checkbox__icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  visibility: hidden;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 0.63158rem;
  width: 0.63158rem;
  background: url("../img/cross.svg") center center no-repeat;
  background-size: contain;
  content: '';
}

.no-csstransforms .checkbox__icon:before {
  top: 0.21053rem;
  left: 0.21053rem;
}

@media (min-width: 1024px) {
  .checkbox__icon {
    height: 13px;
    width: 13px;
  }
  .checkbox__icon:before {
    top: 2px;
    left: 2px;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    height: 7px;
    width: 7px;
  }
}

@media (min-width: 1260px) {
  .checkbox__icon {
    height: 17px;
    width: 17px;
  }
  .checkbox__icon:before {
    top: 3px;
    left: 3px;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    height: 9px;
    width: 9px;
  }
}

@media (min-width: 1850px) {
  .checkbox__icon {
    height: 22px;
    width: 22px;
  }
  .checkbox__icon:before {
    top: 4px;
    left: 4px;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    height: 12px;
    width: 12px;
  }
}

@media (max-width: 479px) {
  .checkbox__icon {
    height: 14px;
    width: 14px;
  }
  .checkbox__icon:before {
    top: 2px;
    left: 2px;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    height: 8px;
    width: 8px;
  }
}

@media (max-width: 400px) {
  .checkbox__icon {
    height: 13px;
    width: 13px;
  }
  .checkbox__icon:before {
    height: 7px;
    width: 7px;
  }
}

@media (max-width: 374px) {
  .checkbox__icon {
    height: 12px;
    width: 12px;
  }
  .checkbox__icon:before {
    height: 6px;
    width: 6px;
  }
}

@media (max-width: 359px) {
  .checkbox__icon {
    width: 11px;
    height: 11px;
  }
  .checkbox__icon:before {
    height: 5px;
    width: 5px;
  }
}

.checkbox > input[type='radio'],
.checkbox > input[type='checkbox'] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;
}

.checkbox.disabled {
  cursor: default;
  cursor: not-allowed;
}

.checkbox.disabled .checkbox__icon {
  border-color: #aaa;
  background-color: #ebebeb;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.checkbox .form__error {
  padding-left: 0;
  padding-right: 0;
}

.checkbox.checked .checkbox__icon:before,
.checkbox-plain-js.checked .checkbox__icon:before {
  visibility: visible;
}

.checkbox--radio .checkbox__icon {
  border-radius: 50%;
}

.checkbox--radio .checkbox__icon:before {
  background: #6dafe0;
  border-radius: 50%;
}

.checkbox--block {
  display: block;
}

.checkbox--inline {
  display: inline;
  margin-right: 0;
  margin-bottom: 0;
}

.checkboxes--justify .checkboxes__inner {
  font-size: 0;
  text-align: justify;
}

.checkboxes--justify .checkboxes__inner:after {
  display: inline-block;
  width: 100%;
  content: '';
}

.checkboxes--justify .checkbox {
  font-size: 1rem;
}

.checkbox--vert {
  padding-top: 1.78947rem;
  margin-top: 0.21053rem;
  padding-right: 0;
  padding-left: 0;
}

[dir='ltr'] .checkbox--vert {
  padding-left: 0;
  padding-right: 0;
}

.checkbox--vert .checkbox__icon {
  top: 0;
  right: 50%;
  margin-right: -0.57895rem;
}

[dir='ltr'] .checkbox--vert .checkbox__icon {
  left: auto;
}

.checkbox--separate {
  width: 1.15789rem;
  height: 1.15789rem;
  padding-left: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.checkbox--separate .checkbox__icon {
  top: 0;
}

.checkbox--separate .checkbox__icon:after {
  top: 0.36842rem;
}

/*

Selectboxes

*/
.selectbox-wrapper {
  position: relative;
  display: inline-block;
  max-width: 100%;
  margin-left: 1.05263rem;
}

.selectbox-wrapper:first-child {
  margin-left: 0;
}

.selectbox-wrapper--fullwidth {
  width: 100%;
}

select.selectbox {
  vertical-align: top;
  height: 2.63158rem;
  line-height: 2.52632rem;
  padding: 0 1.05263rem;
  font-family: "Heebo", Tahoma, Arial, Helvetica, sans-serif;
  font-size: 1.10526rem;
  font-weight: 700;
  border: 1px solid #103f79;
  -webkit-box-shadow: inset -0.21053rem 0.31579rem 0.26316rem rgba(0, 0, 0, 0.2);
          box-shadow: inset -0.21053rem 0.31579rem 0.26316rem rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #103f79;
  border-radius: 0;
  width: 100%;
  overflow: hidden;
}

select.selectbox ::-webkit-scrollbar {
  display: none;
}

select.selectbox:hover {
  border-color: #97969B;
}

select.selectbox.error {
  border-color: #e00;
}

select.selectbox.error + .select2-container .select2-selection {
  border-color: #e00;
}

select.selectbox[disabled] {
  background-color: #ebebeb;
  border-color: transparent;
}

select.selectbox option {
  padding: 0.42105rem 0;
}

select.selectbox--inline {
  width: auto;
}

select.selectbox--inline-width-auto + .select2-container {
  width: auto !important;
}

select.hidden + .select2-container {
  display: none;
}

select.selectbox--fullwidth + .select2 {
  width: 100% !important;
}

.select2-container--default {
  font-size: 1.10526rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: initial;
}

.select2.select2-container--default {
  max-width: 100%;
}

.select2-container--default .select2-selection {
  border: 1px solid #103f79;
  -webkit-box-shadow: inset -0.21053rem 0.31579rem 0.26316rem rgba(0, 0, 0, 0.2);
          box-shadow: inset -0.21053rem 0.31579rem 0.26316rem rgba(0, 0, 0, 0.2);
  border-radius: 0 !important;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s;
  transition: background-color 0.2s, border-color 0.2s;
}

.select2-container--default .select2-selection:hover {
  border-color: #103f79;
}

.select2-container--focus .select2-selection,
.select2-container--open .select2-selection,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #103f79;
  border-radius: 0 !important;
  -webkit-box-shadow: inset -0.21053rem 0.31579rem 0.52632rem rgba(0, 0, 0, 0.1);
          box-shadow: inset -0.21053rem 0.31579rem 0.52632rem rgba(0, 0, 0, 0.1);
}

.select2-container--disabled .select2-selection {
  border-color: transparent;
  background-color: #ebebeb;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: default !important;
  cursor: not-allowed !important;
}

.select2-container--disabled .select2-selection:hover {
  border-color: transparent;
}

.select2-container .select2-selection {
  min-height: 2.63158rem;
  height: auto;
  outline: none;
}

.select2-container--default .select2-selection .select2-selection__rendered {
  color: #103f79;
  line-height: 2.52632rem;
}

.select2-container .select2-selection .select2-selection__rendered {
  padding-left: 1.05263rem;
  padding-right: 1.05263rem;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-left: 1.05263rem;
  padding-right: 1.05263rem;
}

.select2-container--default .select2-selection .select2-selection__placeholder {
  color: #103f79;
  visibility: hidden;
  display: none;
}

.select2-container--default .select2-selection--multiple {
  cursor: pointer;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  text-indent: 0;
  max-width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li:before {
  display: none;
}

.select2-placeholder {
  position: absolute;
  top: 0.42105rem;
  left: 0;
  padding-left: 1.05263rem;
  padding-right: 1.05263rem;
  max-width: 100%;
  color: #97969B;
  line-height: 0.84211rem;
  pointer-events: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  display: none;
}

.select2-placeholder__inner {
  position: relative;
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  z-index: 0;
}

.select2-placeholder__inner:before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: -1;
  bottom: 0;
  margin-top: -1px;
  background-color: transparent;
  border-radius: 0 0 0.15789rem 0.15789rem;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.select2-container--placeholder-top .select2-placeholder,
.select2-container--filled .select2-placeholder {
  top: -0.47368rem;
  font-size: 0.63158rem;
  color: #6dafe0;
}

.textfield-placeholder ~ .select2-container--filled .select2-placeholder {
  opacity: 0;
}

.select2-container--disabled .select2-placeholder {
  color: #103f79;
}

.select2-container--placeholder-top .select2-placeholder__inner:before,
.select2-container--filled .select2-placeholder__inner:before {
  background-color: #fff;
}

/*
.select2-container--placeholder-always .select2-placeholder {
    opacity: 1!important;
}*/
.select2-container--default .select2-selection {
  position: relative;
}

.select2-container--default .select2-selection .select2-selection__arrow {
  color: #b4b4b4;
  height: 100%;
  top: 0;
  width: 2.31579rem;
}

.select2-container--focus .select2-selection .select2-selection__arrow,
.select2-container--open .select2-selection .select2-selection__arrow {
  color: #b4b4b4;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #8f8f8f transparent transparent transparent;
  border-style: solid;
  border-width: 0.68421rem 0.52632rem 0 0.52632rem;
  height: 0;
  left: 50%;
  margin-left: -0.52632rem;
  margin-top: -0.31579rem;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #8f8f8f transparent;
  border-width: 0 0.52632rem 0.68421rem 0.52632rem;
}

.select2-container--disabled .select2-selection {
  border-color: transparent;
  background-color: #ebebeb;
  cursor: default;
  cursor: not-allowed;
}

.select2-container--disabled .select2-selection:hover {
  border-color: transparent;
}

.select2-container--disabled .select2-placeholder__inner:before {
  background-color: transparent;
}

.select2-container--default .select2-selection .select2-selection__clear {
  position: absolute;
  top: -1px;
  right: 0.52632rem;
  z-index: 1;
  width: 2.21053rem;
  height: 1.89474rem;
  margin: 0;
  cursor: pointer;
  text-indent: -526.26316rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.select2-container--default .select2-selection .select2-selection__clear:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  content: '';
  -webkit-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.select2-container--default .select2-selection .select2-selection__clear:hover:before {
  opacity: 1;
}

.select2-container--default .select2-selection.select2-selection--with-clear .select2-selection__rendered {
  padding-right: 2.36842rem;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  position: relative;
  background-color: transparent;
  border-color: #C2C2C2;
  color: #504F56;
  border-radius: 0.10526rem;
  white-space: nowrap;
  height: 1.05263rem;
  line-height: 0.84211rem;
  font-size: 0.63158rem;
  margin: 0.36842rem 0.52632rem 0.21053rem -0.31579rem;
  padding: 0 1.05263rem 0 0.31579rem;
  overflow: hidden;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  position: absolute;
  top: 0;
  right: 0;
  width: 0.94737rem;
  height: 0.94737rem;
  line-height: 0.84211rem;
  text-align: center;
  color: #979797;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  /*
    &:after {
        @include position(absolute, -500rem/$k -500rem/$k -500rem/$k -500rem/$k);
        content: '';
    }*/
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove .icon {
  vertical-align: middle;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove:hover:before {
  opacity: 1;
}

.select2-container .select2-selection--multiple.select2-selection--notags {
  cursor: pointer;
}

.select2-container .select2-selection--multiple.select2-selection--notags .select2-selection__rendered {
  display: block;
  padding-left: 0.94737rem;
  padding-bottom: 0;
}

.select2-container--default .select2-selection--multiple.select2-selection--notags .select2-selection__choice {
  background-color: transparent;
  white-space: nowrap;
  float: none;
  height: auto;
  line-height: 1.2;
  border: none;
  font-size: inherit;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: inline;
  cursor: pointer;
}

.select2-container--default .select2-selection--multiple.select2-selection--notags .select2-selection__choice:before {
  display: none;
  background: none;
  position: static;
  margin: 0;
  padding: 0;
  border-radius: 0;
  content: ",\00a0";
}

.select2-container--default .select2-selection--multiple.select2-selection--notags .select2-selection__choice + .select2-selection__choice:before {
  display: inline;
}

.select2-container--default .select2-selection--multiple.select2-selection--notags .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}

.select2-container--default .select2-selection--tags-create .select2-placeholder {
  display: none;
}

.select2-container--default .select2-search--inline {
  margin: 0 0 -0.26316rem 0;
  padding: 0;
}

.selectbox--nosearch-inline + .select2-container--default .select2-search--inline {
  display: none;
}

.select2-dropdown {
  border: 1px solid #97969B;
  border-radius: 0 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-top: 1px;
}

.select2-dropdown:before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid #97969B;
  content: '';
  z-index: 1;
}

.select2-dropdown--below {
  margin-top: -1px;
}

.select2-dropdown--below:before {
  top: 0;
  bottom: auto;
}

.select2-search--dropdown {
  padding: 0.78947rem 0.78947rem 0;
}

.select2-search--dropdown .select2-search__field {
  padding: 0.36842rem 0.63158rem;
  border-color: #97969B;
  padding-right: 1.84211rem;
}

.select2-search--dropdown .select2-search__field:focus::-webkit-input-placeholder {
  opacity: 0;
}

.select2-search--dropdown .select2-search__field:focus::-moz-placeholder {
  opacity: 0;
}

.select2-search--dropdown .select2-search__field:focus:-moz-placeholder {
  opacity: 0;
}

.select2-search--dropdown .select2-search__field:focus:-ms-input-placeholder {
  opacity: 0;
}

.select2-container--default .select2-results {
  padding: 0;
}

.select2-container--default .select2-results__group {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: #fff;
  background-attachment: local;
  padding: 0.36842rem 0.63158rem;
}

.select2-results {
  /*.select2-results__options--scrollbar {
        padding-right: 5rem/$k;
        margin-right: -10rem/$k;
        margin-top: -5rem/$k;
        margin-bottom: -5rem/$k;
        padding-top: 5rem/$k;
        padding-bottom: 5rem/$k;
    }*/
}

.select2-results ::-webkit-scrollbar:vertical {
  width: 0.26316rem;
}

.select2-results ::-webkit-scrollbar-track {
  border: none;
}

.select2-results ::-webkit-scrollbar-thumb {
  border: none;
}

.select2-container--default .select2-results > .select2-results__options {
  overflow-x: hidden;
}

.select2-container--default .select2-results__option {
  padding: 0.36842rem 0.63158rem;
  line-height: 1.2;
  text-indent: 0;
  margin-top: 0;
  -webkit-transition: background-color 0.2s, color 0.2s;
  -o-transition: background-color 0.2s, color 0.2s;
  transition: background-color 0.2s, color 0.2s;
}

.select2-container--default .select2-results__option:before {
  display: none;
}

.select2-container--default .select2-results__option.select2-results__message {
  cursor: default;
  background-color: rgba(196, 201, 204, 0.25);
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #888;
  cursor: default;
  cursor: not-allowed;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(109, 175, 224, 0.25);
  color: #000;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: rgba(109, 175, 224, 0.15);
}

.select2-container--default [aria-multiselectable="true"] .select2-results__option[aria-selected=true] {
  position: absolute;
  left: -526.26316rem;
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option {
  padding-left: 1.84211rem;
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option:before {
  display: block;
  position: absolute;
  top: 0.57895rem;
  left: 0.57895rem;
  width: 0.57895rem;
  height: 0.42105rem;
  border-radius: 0;
  background: url("../img/checkbox-tick.svg") center center no-repeat;
  background-size: contain;
  content: '';
  visibility: hidden;
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__message {
  padding-left: 1.05263rem;
}

.select2-container--default .select2-results__option--highlighted[aria-selected=true] {
  background-color: rgba(109, 175, 224, 0.15);
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option[aria-selected=true] {
  position: relative;
  left: auto;
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option[aria-selected=true]:before {
  visibility: visible;
}

.select2-container--default .select2-dropdown--notags .select2-results__option--highlighted[aria-selected] {
  background-color: rgba(109, 175, 224, 0.15);
}

.select2-container--default .select2-dropdown--notags [aria-multiselectable="true"] .select2-results__option--highlighted[aria-selected=true] {
  background-color: rgba(109, 175, 224, 0.15);
}

select.selectbox--small {
  height: 1.47368rem;
  line-height: 1.47368rem;
  font-size: 0.68421rem;
}

.selectbox--small + .select2-container {
  font-size: 0.68421rem;
}

.selectbox--small + .select2-container .select2-selection {
  min-height: 1.47368rem;
}

.selectbox--small + .select2-container--default .select2-selection .select2-selection__rendered {
  line-height: 1.31579rem;
  padding-top: 1px;
}

.selectbox--small + .select2-container--default .select2-placeholder {
  top: 0.31579rem;
}

.selectbox--small + .select2-container--placeholder-top .select2-placeholder,
.selectbox--small + .select2-container--filled .select2-placeholder {
  top: -0.42105rem;
  font-size: 0.57895rem;
}

.selectbox--small + .select2-container--default .select2-selection .select2-selection__arrow {
  top: 0.42105rem;
}

.selectbox--small + .select2-container--default .select2-selection .select2-selection__clear {
  height: 1.47368rem;
}

.selectbox--small + .select2-container--default .select2-selection .select2-selection__clear:before {
  background-position: center 0.42105rem;
}

.select2-container--tags-destination .select2-selection__arrow {
  display: none;
}

.select2-container--tags-destination .select2-selection {
  cursor: default;
  border-color: #c3c3c3 !important;
}

.select2-container--tags-destination .select2-selection:hover {
  border-color: #c3c3c3 !important;
}

.select2-container--tags-destination .select2-selection .select2-selection__rendered {
  padding-right: 0.52632rem;
}

.select2-container--tags-destination + .select2-container--tags-destination {
  display: none !important;
}

.selectbox-tags__source {
  position: relative;
  margin-bottom: 1.05263rem;
}

.selectbox-tags__source .select2-container + .select2-container {
  position: relative !important;
  top: auto !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  margin-top: 0.52632rem;
}

.selectbox-tags__source .select2-container + .select2-container .select2-dropdown {
  position: relative !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  z-index: auto;
}

.selectbox-wrapper .select2-container--dropdown {
  top: 100% !important;
  margin-top: -1px;
}

.selectbox-wrapper .select2-container--above + .select2-container--dropdown {
  bottom: 100% !important;
  top: auto !important;
  margin-bottom: -1px;
  margin-top: 0;
}

body > .select2-container--in-fancybox,
.wrap > .select2-container--in-fancybox {
  z-index: 99995;
}

.select2-container--dropdown {
  z-index: 1051;
}

.select2-container--in-fancybox {
  max-width: 21.57895rem;
}

.select2-container--dropdown-nowrap .select2-results__option {
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}

/*

Panels

 */
.panel {
  background-color: #f9f9f9;
  border-radius: 0.31579rem;
  -webkit-box-shadow: 0 0.10526rem 0.21053rem rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.10526rem 0.21053rem rgba(0, 0, 0, 0.1);
  margin-top: 2.63158rem;
  width: 18.94737rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1.57895rem;
}

.panel:first-child {
  margin-top: 0;
}

.panel--wide {
  width: 39.47368rem;
  padding: 1.57895rem 2.10526rem 2.63158rem;
}

/*

Tabs

 */
.tabs-wrapper {
  position: relative;
  z-index: 0;
  margin-top: 5.78947rem;
}

.tabs-wrapper:first-child {
  margin-top: 0;
}

.tabs-wrapper--w-search .tabs {
  padding-right: 3.15789rem;
}

.tabs {
  position: relative;
  z-index: 0;
  margin-top: 5.78947rem;
  overflow: hidden;
  -webkit-transition: opacity 0.4s, visibility 0.4s;
  -o-transition: opacity 0.4s, visibility 0.4s;
  transition: opacity 0.4s, visibility 0.4s;
}

.tabs:first-child {
  margin-top: 0;
}

.search-active .tabs {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.4s, visibility 0s 0.4s;
  -o-transition: opacity 0.4s, visibility 0s 0.4s;
  transition: opacity 0.4s, visibility 0s 0.4s;
}

.tabs:before {
  position: absolute;
  right: 0;
  bottom: 1px;
  left: 0;
  border-bottom: 1px solid rgba(118, 139, 151, 0.15);
  content: '';
  z-index: -1;
}

.tabs ul {
  display: table;
  width: 100%;
  height: 100%;
  font-size: 0;
}

.tabs li {
  position: relative;
  display: table-cell;
  vertical-align: bottom;
  height: 100%;
  font-size: 0.78947rem;
  font-weight: 500;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  padding: 0 1.84211rem 0 0;
}

.tabs li:last-child {
  padding-right: 0;
}

.tabs li.active a {
  color: #7D5DB8;
  cursor: default;
}

.tabs li.active a:before {
  background-color: #7D5DB8;
}

.tabs li.active a:hover:before {
  background-color: #7D5DB8;
}

.tabs li.disabled {
  cursor: default;
  cursor: not-allowed;
}

.tabs li.disabled:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: block;
  content: "";
}

.tabs li.disabled a {
  color: #888;
}

.tabs .tabs__pointer + ul li.active a:before {
  background-color: transparent;
}

.tabs .tabs__pointer + ul li.active a:hover:before {
  background-color: transparent;
}

.tabs a {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 0 0.68421rem;
  color: #103f79;
  border: none;
  text-align: center;
  text-decoration: none;
}

.tabs a:before {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  height: 0.10526rem;
  background-color: transparent;
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.tabs a:hover {
  color: #103f79;
}

.tabs a:hover:before {
  background-color: rgba(118, 139, 151, 0.15);
}

.tabs__scroll {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  margin-bottom: -1.05263rem;
  padding-bottom: 1.05263rem;
  overflow-y: hidden;
  overflow-x: hidden;
}

.touchevents .tabs__scroll {
  overflow-x: auto;
}

.touchevents .tabs__scroll::-webkit-scrollbar {
  display: none;
}

.tabs__scroll-inner {
  position: relative;
  display: inline-block;
  min-width: 100%;
  vertical-align: top;
  font-size: 0.73684rem;
}

.tabs__inner {
  position: relative;
}

.tabs__inner:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 1.15789rem;
  width: 1.57895rem;
  background-image: -webkit-linear-gradient(left, #fff, rgba(255, 255, 255, 0));
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), to(rgba(255, 255, 255, 0)));
  background-image: -o-linear-gradient(left, #fff, rgba(255, 255, 255, 0));
  background-image: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
  content: "";
  z-index: 2;
  pointer-events: none;
}

.tabs__inner:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 1.15789rem;
  width: 1.57895rem;
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#fff));
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0), #fff);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  content: "";
  z-index: 2;
  pointer-events: none;
}

.tabs__inner.scroll-in-start:before {
  opacity: 0;
  visibility: hidden;
}

.tabs__inner.scroll-in-end:after {
  opacity: 0;
  visibility: hidden;
}

.tabs__pointer {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.tabs__pointer:before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.10526rem;
  background-color: #7D5DB8;
  content: '';
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s;
  -o-transition: opacity 0.2s, visibility 0s 0.2s;
  transition: opacity 0.2s, visibility 0s 0.2s;
}

.tabs__pointer.active:before {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.tabs--equals ul {
  table-layout: fixed;
}

.tabs--inline ul {
  display: block;
}

.tabs--inline li {
  display: inline-block;
}

.tabs--flex ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}

.tabs-item-end {
  margin-left: auto;
}

.tab-panes {
  position: relative;
  margin-top: 1.31579rem;
}

.tab-panes:first-child {
  margin-top: 0;
}

.tab-pane {
  position: absolute;
  top: -526.26316rem;
}

.tab-pane.active {
  position: relative;
  top: 0;
}

.tab-pane--smoothly {
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  -webkit-transition: opacity 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
  -o-transition: opacity 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
  transition: opacity 0.2s, visibility 0s 0.2s, z-index 0s 0.2s;
}

.tab-pane--smoothly.active {
  opacity: 1;
  visibility: visible;
  z-index: 1;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

/*

Pager (including for JS dataTables plugin)

 */
.pager {
  font-size: 0;
  color: #103f79;
  margin-top: 4.21053rem;
  margin-right: -0.52632rem;
  margin-bottom: -0.52632rem;
}

.pager:first-child {
  margin-top: 0;
}

.pager__item {
  display: inline-block;
  vertical-align: top;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.89474rem;
  padding: 0 0.52632rem;
  min-width: 2.63158rem;
  height: 2.63158rem;
  line-height: 2.63158rem;
  color: #103f79;
  background-color: #F9FAFC;
  border: 1px solid #E9EDF5;
  margin: 0 0.52632rem 0.52632rem 0;
  border-radius: 0.10526rem;
  text-align: center;
  cursor: default;
}

.pager__item.active, .pager__item.current {
  background-color: #B9C0CA;
  border-color: #B9C0CA;
  color: #fff;
  cursor: default;
  pointer-events: none;
}

.pager__item.active:hover, .pager__item.current:hover {
  color: #fff;
}

.pager__item a {
  text-decoration: none;
}

.pager__item .icon {
  font-size: 0.36842rem;
  width: 3em;
  vertical-align: middle;
}

.pager__item--prev, .pager__item--next {
  line-height: 2.42105rem;
}

.pager__item--pseudo {
  border-color: transparent;
  background-color: transparent;
  min-width: 2.10526rem;
  line-height: 2.10526rem;
  font-size: 1rem;
}

a.pager__item {
  text-decoration: none;
  cursor: pointer;
}

a.pager__item:hover {
  color: #5D41A8;
}

/*

Sliders

 */
.swiper-slider {
  position: relative;
  display: block;
}

.swiper-slider .swiper-container {
  background-color: #222;
}

.swiper-slider .swiper-button-prev,
.swiper-slider .swiper-button-next {
  position: absolute;
  margin-top: -1.68421rem;
  margin-left: 0 !important;
  background: none;
  height: 3.36842rem;
  width: 2rem;
  z-index: 1;
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-button-prev,
  .swiper-slider .swiper-button-next {
    margin-top: 0;
    margin-left: 1.05263rem !important;
    height: 2.84211rem;
    width: 2.84211rem;
    background: #103f79;
    border-radius: 50%;
    top: auto;
    bottom: 1.05263rem;
  }
}

.lt-ie9 .swiper-slider .swiper-button-prev, .lt-ie9
.swiper-slider .swiper-button-next {
  display: none;
}

.swiper-slider .swiper-button-prev *,
.swiper-slider .swiper-button-next * {
  vertical-align: middle;
}

.swiper-slider .swiper-button-prev:before,
.swiper-slider .swiper-button-next:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url("../img/slider-chevrons.png") -526.26316rem center no-repeat;
  background-size: auto 100%;
  content: '';
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-button-prev:before,
  .swiper-slider .swiper-button-next:before {
    margin-top: -0.73684rem;
    margin-left: -0.36842rem;
    height: 1.47368rem;
    width: 0.84211rem;
    left: 50%;
    top: 50%;
  }
}

.swiper-slider .swiper-button-prev:after,
.swiper-slider .swiper-button-next:after {
  position: absolute;
  top: -1.31579rem;
  right: -1.31579rem;
  bottom: -1.31579rem;
  left: -1.31579rem;
  content: '';
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-button-prev:after,
  .swiper-slider .swiper-button-next:after {
    display: none;
  }
}

.swiper-slider .swiper-button-prev {
  left: 1.31579rem;
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-button-prev {
    left: 0;
    right: auto;
  }
}

.swiper-slider .swiper-button-prev:before {
  background-position-x: left;
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-button-prev:before {
    margin-left: -0.47368rem;
  }
}

.swiper-slider .swiper-button-next {
  right: 1.31579rem;
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-button-next {
    left: 3.68421rem;
  }
}

.swiper-slider .swiper-button-next:before {
  background-position-x: right;
}

.swiper-slider .swiper-container-rtl .swiper-button-prev {
  right: 1.31579rem;
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-container-rtl .swiper-button-prev {
    left: 3.68421rem;
    right: auto;
  }
}

.swiper-slider .swiper-container-rtl .swiper-button-prev:before {
  background-position-x: right;
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-container-rtl .swiper-button-prev:before {
    margin-left: -0.36842rem;
  }
}

.swiper-slider .swiper-container-rtl .swiper-button-next {
  left: 1.31579rem;
  right: auto;
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-container-rtl .swiper-button-next {
    left: 0;
  }
}

.swiper-slider .swiper-container-rtl .swiper-button-next:before {
  background-position-x: left;
}

@media (max-width: 1023px) {
  .swiper-slider .swiper-container-rtl .swiper-button-next:before {
    margin-left: -0.47368rem;
  }
}

.swiper-slider .swiper-button-next.swiper-button-disabled, .swiper-slider .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-slider-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

@media (max-width: 1023px) {
  .swiper-slider-bottom {
    position: relative;
  }
}

.swiper-slide__image {
  position: relative;
  background: center center no-repeat;
  background-size: cover;
  min-height: 300px;
}

@media (max-width: 1023px) {
  .swiper-slide__image {
    padding-bottom: 75%;
    min-height: 0;
  }
}

.swiper-slide__image.img-to-bg img {
  visibility: hidden;
}

@media (max-width: 1023px) {
  .swiper-slide__image.img-to-bg img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.swiper-slider-overlay {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin-top: -14.21053rem;
  line-height: 26.31579rem;
}

@media (max-width: 1023px) {
  .swiper-slider-overlay {
    margin-top: -13.15789rem;
  }
}

.swiper-slider-overlay__inner {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  line-height: 1.31579;
}

.swiper-slider-overlay__title {
  font-size: 5.63158rem;
  font-weight: 900;
  line-height: 1.15;
  color: #dbdbdb;
  text-shadow: 0 0 1.57895rem rgba(0, 0, 0, 0.16), 0 0 1.57895rem rgba(0, 0, 0, 0.16), 0 0 1.57895rem rgba(0, 0, 0, 0.16), 0 0 1.57895rem rgba(0, 0, 0, 0.16), 0 0 2.10526rem rgba(0, 0, 0, 0.25), 0 0 2.10526rem rgba(0, 0, 0, 0.25);
}

[dir='ltr'] .swiper-slider-overlay__title {
  line-height: 0.9;
}

@media (max-width: 1023px) {
  .swiper-slider-overlay__title {
    font-size: 3.52632rem;
  }
}

.swiper-slider-overlay__subtitle {
  font-size: 3.10526rem;
  font-weight: 700;
  color: #103f79;
  text-shadow: 0 0 0.42105rem rgba(255, 255, 255, 0.5), 0 0 0.42105rem rgba(255, 255, 255, 0.5), 0 0 0.52632rem rgba(255, 255, 255, 0.5), 0 0 0.52632rem rgba(255, 255, 255, 0.5), 0 0 0.52632rem rgba(255, 255, 255, 0.5), 0 0 0.68421rem rgba(255, 255, 255, 0.75), 0 0 0.68421rem rgba(255, 255, 255, 0.75), 0 0 0.68421rem rgba(255, 255, 255, 0.75);
}

[dir='ltr'] .swiper-slider-overlay__subtitle {
  margin-top: 0.52632rem;
}

[dir='ltr'] .swiper-slider-overlay__subtitle:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .swiper-slider-overlay__subtitle {
    font-size: 1.94737rem;
  }
}

/*

Popups

 */
.fancybox-enabled {
  overflow: hidden;
}

.fancybox-thumbs > ul > li:before {
  width: auto;
  height: auto;
  margin: 0;
  background: none;
  border-radius: 0;
}

.fancybox-container ~ .fancybox-container {
  z-index: 9992;
}

.fancybox-container {
  z-index: 9993;
}

.fancybox-container--popup .fancybox-inner {
  -webkit-transform: translateY(-5%);
      -ms-transform: translateY(-5%);
          transform: translateY(-5%);
  opacity: 0;
  -webkit-transition-duration: 0.5s !important;
       -o-transition-duration: 0.5s !important;
          transition-duration: 0.5s !important;
}

.fancybox-container--popup .fancybox-bg {
  background: #66717E;
}

.fancybox-container--popup.fancybox-is-open .fancybox-inner {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}

.fancybox-container--popup.fancybox-is-open .fancybox-bg {
  opacity: .7;
}

.fancybox-container--popup .fancybox-slide {
  overflow-x: hidden;
  font-size: 0;
  white-space: nowrap;
}

.fancybox-container--popup .fancybox-slide > * {
  white-space: normal;
  font-size: 0.73684rem;
  padding: 0;
  background-color: transparent;
  overflow: visible;
}

@media (max-width: 359px) {
  .fancybox-container--popup .fancybox-slide > * {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
  }
}

.fancybox-container--popup .fancybox-slide > .fancybox-loading {
  width: 2.63158rem;
  margin-top: -1.31579rem;
}

.fancybox-thumbs {
  background-color: #000;
}

.fancybox-thumbs > ul > li {
  border-color: transparent;
}

.fancybox-thumbs > ul > li:before {
  border-color: #6dafe0;
  border-width: 0.10526rem;
}

.fancybox-show-thumbs .fancybox-button--thumbs {
  background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 1023px) {
  .fancybox-caption-wrap {
    padding-top: 2.10526rem;
  }
}

@media (max-width: 1023px) {
  .fancybox-caption {
    padding-top: 0.78947rem;
    padding-bottom: 0.78947rem;
  }
}

.close {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 0.78947rem;
  line-height: 1;
  font-size: 1.15789rem;
  color: #f9f9f9;
  opacity: 0.6;
  cursor: pointer;
}

.close:hover {
  color: #f9f9f9;
  opacity: 1;
}

/*

Tooltips

 */
/*
.tooltip {
    cursor: default;
}

a.tooltip,
.btn.tooltip {
    cursor: pointer;
}*/
.tooltipster-default .tooltipster-content {
  position: relative;
  font-size: 0.52632rem;
  font-weight: 400;
  line-height: 1.4;
  padding: 0.42105rem 0.78947rem;
}

.tooltipster-default .tooltipster-box {
  background-color: #66717E;
  color: #fff;
  border-radius: 0.15789rem;
  border: 1px solid transparent;
  margin: 0.52632rem;
}

.tooltipster-default .tooltipster-box a {
  color: inherit;
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.tooltipster-default .tooltipster-box a:hover {
  color: inherit;
  border-bottom-color: rgba(255, 255, 255, 0.75);
}

.tooltipster-default .tooltipster-box p {
  margin-top: 0.52632rem;
}

.tooltipster-default .tooltipster-box p:first-child {
  margin-top: 0;
}

.tooltipster-compact .tooltipster-content {
  padding: 0.21053rem 0.42105rem;
}

.tooltipster-as-action .tooltipster-box:hover {
  background-color: #5b5b5b;
}

.tooltipster-as-action .tooltipster-box:active {
  background-color: #666;
}

.tooltipster-default.tooltipster-top .tooltipster-arrow {
  bottom: -1px;
}

.tooltipster-default.tooltipster-bottom .tooltipster-arrow {
  top: -1px;
}

.tooltipster-sidetip .tooltipster-arrow-background {
  display: none;
}

.tooltipster-default.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #66717E;
}

.tooltipster-default .tooltipster-arrow-border {
  border-width: 0.47368rem;
}

.tooltipster-default.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -0.47368rem;
}

.tooltipster-default.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #66717E;
}

.tooltipster-default.tooltipster-top .tooltipster-arrow-uncropped {
  top: 1px;
}

.tooltipster-default.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #66717E;
}

.tooltipster-default.tooltipster-right .tooltipster-arrow-uncropped {
  left: -0.47368rem;
}

.tooltipster-default.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #66717E;
}

.tooltipster-default.tooltipster-left .tooltipster-arrow-uncropped {
  left: 1px;
}

.tooltipster-header {
  font-size: 0.73684rem;
  line-height: 1.2857;
}

.touchevents .tooltipster-base.tooltipster-hide-on-mobiles {
  display: none;
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  color: #fff;
  z-index: 10;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 1023px) {
  .header {
    position: relative;
  }
}

.header__logo {
  height: 3.57895rem;
  position: relative;
  z-index: 1;
}

@media (max-width: 1023px) {
  .header__logo {
    height: auto;
    display: inline-block;
    vertical-align: top;
  }
}

@media (max-width: 1023px) {
  .header__inner {
    position: relative;
    background-color: #103f79;
    padding-bottom: 1.05263rem;
    z-index: 10;
  }
}

.header__top {
  background-color: #103f79;
}

.header__grid-wrapper {
  margin: 0 -0.52632rem;
  direction: ltr;
}

.header__grid {
  display: table;
  width: 100%;
}

@media (max-width: 1023px) {
  .header__grid {
    display: block;
    text-align: center;
  }
}

.header__cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0 0.52632rem;
  direction: rtl;
}

[dir='ltr'] .header__cell {
  direction: ltr;
}

@media (max-width: 1023px) {
  .header__cell {
    display: block;
    padding: 0;
  }
}

.header__cell--fit {
  width: 1%;
}

@media (max-width: 1023px) {
  .header__cell--fit {
    width: auto;
  }
}

@media (max-width: 1023px) {
  .header__cell--language {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 1.05263rem;
    margin-left: 0.78947rem;
  }
}

[dir='ltr'] .header__cell--phone {
  text-align: right;
}

@media (max-width: 1023px) {
  .header__cell--phone {
    margin-top: 0.78947rem;
  }
  .header__cell--phone:first-child {
    margin-top: 0;
  }
}

.header__cell--nav-icons {
  white-space: nowrap;
}

@media (max-width: 1023px) {
  .header__cell--nav-icons {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background-color: #103f79;
    margin: 0 -0.26316rem 0 -0.52632rem;
    padding: 1.05263rem 2.63158rem;
    white-space: normal;
  }
  .mscrolled .header__cell--nav-icons {
    position: fixed;
    top: 0;
  }
  .header__cell--nav-icons:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    content: '';
  }
}

.header__language {
  float: left;
  margin-left: 1.05263rem;
  direction: rtl;
}

.header__language:first-child {
  margin-left: 0;
}

@media (max-width: 1023px) {
  .header__nav-icons {
    text-align: justify;
    font-size: 0.1em;
  }
  .header__nav-icons:after {
    display: inline-block;
    width: 100%;
    content: '';
  }
}

.header__nav-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: initial;
  height: 1.89474rem;
  width: 1.89474rem;
  background: url("../img/nav-icons.png") -526.26316rem center no-repeat;
  background-size: auto 100%;
}

@media (max-width: 1023px) {
  .header__nav-icon {
    height: 2.42105rem;
    width: 2.42105rem;
  }
  .header__nav-icon:before {
    position: absolute;
    top: -1.05263rem;
    right: -1.05263rem;
    bottom: -1.05263rem;
    left: -1.05263rem;
    content: '';
  }
}

.header__nav-icon--menu {
  background-position-x: -26.05263rem;
}

.header__nav-icon--search {
  background: url("../img/icon-search.png") center center no-repeat;
  background-size: contain;
}

@media (max-width: 1023px) {
  .header__nav-icon--search {
    background-image: url("../img/nav-icons.png");
    background-size: auto 100%;
    background-position-x: -19.52632rem;
  }
}

.header__nav-icon--marker {
  background-position-x: -12.94737rem;
}

.header__nav-icon--mail {
  background-position-x: -6.36842rem;
}

.header__nav-icon--phone {
  background-position-x: 0.26316rem;
}

.header__phone {
  font-size: 1.10526rem;
  font-weight: 700;
}

.header__phone-value {
  font-size: 1.26316rem;
  margin-left: 0.52632rem;
  position: relative;
  top: 1px;
}

[dir='ltr'] .header__phone-value {
  margin-left: 0;
  margin-right: 0.78947rem;
}

.header__phone-label {
  position: relative;
  top: -1px;
}

.header__phone-nbsps {
  display: inline-block;
  width: 0.9em;
}

@media (max-width: 1023px) {
  .header__phone-nbsps {
    width: 0.2em;
  }
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header a:hover {
  color: #6dafe0;
}

.header-replace {
  height: 7.15789rem !important;
}

@media (max-width: 1023px) {
  .header-replace {
    height: 4.47368rem !important;
  }
}

.header-thin .header-replace {
  height: 3.57895rem !important;
}

@media (max-width: 1023px) {
  .header-thin .header-replace {
    height: 4.47368rem !important;
  }
}

.scrolled .header,
.header-solid .header,
.hsearch-active .header {
  background-color: #103f79;
  -webkit-box-shadow: 0 0 2.63158rem rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 2.63158rem rgba(0, 0, 0, 0.5);
}

@media (max-width: 1023px) {
  .scrolled .header,
  .header-solid .header,
  .hsearch-active .header {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}

.logo {
  position: relative;
  z-index: 0;
  display: block;
  width: 21.57895rem;
  padding: 0 1.57895rem 0.52632rem;
  height: 7.15789rem;
  line-height: 6.63158rem;
}

@media (max-width: 1023px) {
  .logo {
    width: 18.68421rem;
    height: 6.31579rem;
    line-height: 5.78947rem;
    overflow: visible !important;
  }
}

.logo:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 7.73684rem;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(50%, white), color-stop(75%, rgba(255, 255, 255, 0.6)), to(rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, #fff 0%, white 50%, rgba(255, 255, 255, 0.6) 75%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, #fff 0%, white 50%, rgba(255, 255, 255, 0.6) 75%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, #fff 0%, white 50%, rgba(255, 255, 255, 0.6) 75%, rgba(255, 255, 255, 0) 100%);
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.lte-ie9 .logo:before {
  background: url("../img/logo-before.svg") center top repeat-x;
  background-size: cover;
}

@media (max-width: 1023px) {
  .logo:before {
    top: -15.78947rem;
    right: 2.10526rem;
    bottom: 2.63158rem;
    left: 2.36842rem;
    height: auto;
    background: #fff !important;
    -webkit-box-shadow: 0 0 2.36842rem 2.63158rem #fff;
            box-shadow: 0 0 2.36842rem 2.63158rem #fff;
    z-index: 0;
  }
}

.logo:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(40%, white), color-stop(50%, rgba(255, 255, 255, 0.3)), to(rgba(255, 255, 255, 0.5)));
  background-image: -webkit-linear-gradient(top, #fff 0%, white 40%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.5) 100%);
  background-image: -o-linear-gradient(top, #fff 0%, white 40%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.5) 100%);
  background-image: linear-gradient(to bottom, #fff 0%, white 40%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.5) 100%);
  content: '';
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.lte-ie9 .logo:after {
  background: url("../img/logo-after.svg") center top repeat-x;
  background-size: cover;
}

.scrolled .logo:after {
  opacity: 1;
}

.header-solid .logo:after {
  opacity: 1;
}

.hsearch-active .logo:after {
  opacity: 0;
}

@media (max-width: 1023px) {
  .logo:after {
    display: none;
  }
}

.logo__image {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding-bottom: 27.76119%;
  background: url("../img/logo.svg") center center no-repeat;
  background-size: contain;
}

@media (max-width: 1023px) {
  .logo__image {
    z-index: 2;
  }
}

.scrolled .logo {
  overflow: hidden;
}

.header-solid .logo {
  overflow: hidden;
}

.hsearch-active .logo {
  overflow: visible;
}

.language ul {
  font-size: 0;
  white-space: nowrap;
  margin-right: -0.78947rem;
}

@media (max-width: 1023px) {
  .language ul {
    margin-right: -0.26316rem;
  }
}

.language__item {
  display: inline-block;
  vertical-align: top;
  font-size: 0.94737rem;
  font-weight: 700;
  line-height: 1.2;
  margin-right: 0.78947rem;
}

@media (max-width: 1023px) {
  .language__item {
    margin-right: 0.26316rem;
  }
}

.language__item a {
  border-bottom: 0.26316rem solid transparent;
  padding-bottom: 1px;
}

@media (max-width: 1023px) {
  .language__item a {
    border-bottom: none;
    padding: 0.10526rem 0.21053rem 0;
  }
}

.language__item.active a {
  border-bottom-color: #6dafe0;
}

@media (max-width: 1023px) {
  .language__item.active a {
    background-color: #6dafe0;
  }
}

.language__item-flag {
  position: relative;
  top: -1px;
  display: inline-block;
  vertical-align: middle;
  height: 1.05263rem;
  width: 1.47368rem;
  margin-left: 0.10526rem;
  background: url("../img/flags.png") center -526.26316rem no-repeat;
  background-size: 100% auto;
}

@media (max-width: 1023px) {
  .language__item-flag {
    display: none;
  }
}

.language__item-flag--en {
  background-position-y: 0;
}

.language__item-flag--ru {
  background-position-y: -1.10526rem;
}

.language__item-flag--il {
  background-position-y: -2.15789rem;
}

.language .latin {
  font-size: 100%;
}

.hsearch {
  max-height: 0;
  background-color: #6dafe0;
  overflow: hidden;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 1023px) {
  .hsearch {
    display: none;
  }
}

@media (max-width: 1023px) {
  .header__cell--nav-icons .hsearch {
    display: block;
    margin: -1.05263rem -2.36842rem 1.05263rem -2.10526rem;
  }
}

.hsearch__inner {
  padding: 0.78947rem 0 0.78947rem 22.42105rem;
  margin: 0 -0.42105rem;
  opacity: 0;
  -webkit-transition: all 0.2s 0.2s;
  -o-transition: all 0.2s 0.2s;
  transition: all 0.2s 0.2s;
}

@media (max-width: 1023px) {
  .hsearch__inner {
    padding: 0.42105rem;
    margin: 0 -2rem;
  }
}

.hsearch-active .hsearch__inner {
  opacity: 1;
  -webkit-transition: all 0.4s 0.06667s;
  -o-transition: all 0.4s 0.06667s;
  transition: all 0.4s 0.06667s;
}

.hsearch__grid {
  display: table;
  width: 100%;
}

.hsearch__cell {
  display: table-cell;
  vertical-align: top;
  padding: 0 0.42105rem;
}

.hsearch__cell--fit {
  width: 1%;
}

.hsearch-active .hsearch {
  max-height: 5.26316rem;
}

.hsearch .textfield {
  font-size: 1.21053rem;
  padding: 0.94737rem 1.05263rem 1.10526rem;
  height: 3.68421rem;
  border-color: transparent;
  background-color: #8dc1e7;
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}

@media (max-width: 1023px) {
  .hsearch .textfield {
    padding-top: 0.73684rem;
    padding-bottom: 0.78947rem;
    height: 3.15789rem;
  }
}

.hsearch .textfield::-webkit-input-placeholder {
  color: #fff;
}

.hsearch .textfield::-moz-placeholder {
  color: #fff;
}

.hsearch .textfield:-moz-placeholder {
  color: #fff;
}

.hsearch .textfield:-ms-input-placeholder {
  color: #fff;
}

.hsearch .btn {
  font-size: 1.21053rem;
  font-weight: 700;
  line-height: 3.68421rem;
  height: 3.68421rem;
  min-width: 9.68421rem;
  text-align: right;
}

[dir='ltr'] .hsearch .btn {
  text-align: left;
}

@media (max-width: 1023px) {
  .hsearch .btn {
    line-height: 3.15789rem;
    height: 3.15789rem;
  }
}

.hsearch .btn:before, .hsearch .btn:after {
  position: absolute;
  top: 50%;
  left: 0.78947rem;
  margin-top: -0.52632rem;
  height: 0.84211rem;
  width: 1.15789rem;
  background: url("../img/icon-square-triangles.png") center -0.94737rem no-repeat;
  background-size: 100% auto;
  content: '';
}

[dir='ltr'] .hsearch .btn:before, [dir='ltr'] .hsearch .btn:after {
  left: auto;
  right: 0.78947rem;
}

.hsearch .btn:before {
  -webkit-transition: all 0.2s, visibility 0s;
  -o-transition: all 0.2s, visibility 0s;
  transition: all 0.2s, visibility 0s;
}

.hsearch .btn:after {
  background-position: center 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.hsearch .btn:hover {
  color: #6dafe0 !important;
  background-color: #103f79;
}

.hsearch .btn:hover:before {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.hsearch .btn:hover:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.hmenu {
  position: relative;
  margin: 0 -0.78947rem;
}

[dir='ltr'] .hmenu {
  text-align: right;
}

.hmenu > ul {
  font-size: 0;
}

.hmenu__item {
  position: static;
  display: inline-block;
  vertical-align: top;
  padding: 0.47368rem 0;
  height: 3.57895rem;
}

.hmenu__item > a {
  display: block;
  padding: 0.52632rem 0.78947rem;
  font-size: 1.21053rem;
  font-weight: 700;
  line-height: 1.57895rem;
}

.hmenu__item.active a {
  color: #103f79;
}

.scrolled .hmenu__item.active a,
.header-solid .hmenu__item.active a,
.hsearch-active .hmenu__item.active a {
  color: #6dafe0;
}

.hmenu__item--w-sub > a {
  padding-bottom: 1.15789rem;
}

.hmenu__item:hover > a {
  color: #fff;
  background-color: #103f79;
}

.scrolled .hmenu__item:hover > a {
  background-color: #0e3667;
}

.header-solid .hmenu__item:hover > a {
  background-color: #0e3667;
}

.hmenu__item:hover .hmenu__sub {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.hmenu__item.focused > a {
  color: #fff;
  background-color: #103f79;
}

.scrolled .hmenu__item.focused > a {
  background-color: #0e3667;
}

.header-solid .hmenu__item.focused > a {
  background-color: #0e3667;
}

.hmenu__item.focused .hmenu__sub {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.hmenu__sub {
  position: absolute;
  top: 100%;
  right: 0.78947rem;
  margin-top: 0.15789rem;
  width: 41.57895rem;
  padding: 0.78947rem 0.78947rem;
  background-color: #103f79;
  text-align: justify;
  font-size: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.scrolled .hmenu__sub {
  background-color: #0e3667;
}

.header-solid .hmenu__sub {
  background-color: #0e3667;
}

.hmenu__sub:after {
  display: inline-block;
  width: 100%;
  content: '';
}

.hmenu__sub ul {
  display: inline-block;
  vertical-align: top;
  text-align: initial;
  padding: 0.52632rem 0.52632rem;
  margin-bottom: -0.26316rem;
}

.hmenu__sub li {
  margin-bottom: 0.26316rem;
}

.hmenu__sub a {
  font-size: 0.94737rem;
}

.hmenu__sub a:hover {
  color: #6dafe0;
}

.mmenu {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin-top: 4.21053rem;
  padding-top: 0.52632rem;
  font-size: 1.57895rem;
  font-weight: 700;
  text-align: center;
  background-color: #103f79;
  color: #fff;
  max-height: none;
  overflow-y: auto;
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
  visibility: hidden;
  transition: transform 0.2s, -webkit-transform 0.2s, visibility 0s 0.2s;
  z-index: 9;
}

.mscrolled .mmenu {
  position: fixed;
  top: 0;
  max-height: calc(100vh - 4.21053rem);
}

.hsearch-active .mmenu {
  margin-top: 8.21053rem;
}

.mmenu-active .mmenu {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.mmenu li {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.mmenu li:first-child {
  border-top: none;
}

.mmenu a {
  display: block;
  padding: 0.42105rem 0;
}

.mmenu a:hover {
  color: #fff;
}

.mmenu a:active {
  background-color: #6dafe0;
}

.mmenu a.active {
  background-color: #6dafe0;
}

.mmenu__sub {
  font-weight: 400;
}

.mmenu__sub ul {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.mmenu__sub ul a {
  background-color: #336d9f;
}

.mmenu__sub ul a:active {
  background-color: #6dafe0;
}

.footer {
  position: relative;
  z-index: 1;
  font-size: 0.94737rem;
  background-color: #103f79;
  color: #fff;
  padding: 1.31579rem 0 2.10526rem;
  margin-top: 3.94737rem;
}

.footer:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .footer {
    margin-top: 2.63158rem;
    font-size: 1.05263rem;
  }
}

.footer__title {
  font-size: 1.57895rem;
  font-weight: 700;
  text-align: center;
  margin-top: 1.31579rem;
}

.footer__title:first-child {
  margin-top: 0;
}

.footer__grid {
  margin-top: 1.31579rem;
}

.footer__grid:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .footer__grid {
    margin-top: 0.26316rem;
    max-width: 26.84211rem;
    margin-left: auto;
    margin-right: auto;
  }
  .footer__grid:first-child {
    margin-top: 0;
  }
}

.footer__grid-inner {
  margin-left: -1.05263rem;
  margin-right: -1.05263rem;
  font-size: 0.1em;
  text-align: justify;
}

.footer__grid-inner:after {
  display: inline-block;
  width: 100%;
  content: '';
}

@media (max-width: 1023px) {
  .footer__grid-inner {
    text-align: initial;
    margin-left: -0.78947rem;
    margin-right: -0.78947rem;
    font-size: 0;
  }
}

.footer__cell {
  display: inline-block;
  vertical-align: top;
  text-align: initial;
  max-width: 25%;
  padding: 0 1.05263rem;
  font-size: 0.94737rem;
  line-height: 1.55556;
}

@media (max-width: 1023px) {
  .footer__cell {
    max-width: none;
    width: 50%;
    padding: 0.78947rem;
    font-size: 1.05263rem;
  }
}

.latin {
  font-size: 82.6087%;
}

.main {
  position: relative;
  z-index: 1;
  padding: 2.10526rem 0;
}

.main:after {
  content: "";
  display: table;
  clear: both;
}

.sidebar {
  float: right;
  width: 21.05263rem;
}

@media (max-width: 1023px) {
  .sidebar {
    float: none;
    width: auto;
    max-width: 26.31579rem;
    margin-left: auto;
    margin-right: auto;
  }
}

[dir='ltr'] .sidebar {
  float: left;
}

@media (max-width: 1023px) {
  [dir='ltr'] .sidebar {
    float: none;
  }
}

.sidebar + .content {
  margin-right: 25rem;
}

@media (max-width: 1023px) {
  .sidebar + .content {
    margin-right: 0;
    margin-top: 1.84211rem;
  }
}

[dir='ltr'] .sidebar + .content {
  margin-right: 0;
  margin-left: 25rem;
}

@media (max-width: 1023px) {
  [dir='ltr'] .sidebar + .content {
    margin-left: 0;
  }
}

.content:after {
  content: "";
  display: table;
  clear: both;
}

.content__inner {
  float: left;
  width: 100%;
}

.content--lower {
  padding-top: 1.57895rem;
}

@media (max-width: 1023px) {
  .content--lower {
    padding-top: 0;
  }
}

.smenu {
  position: relative;
  z-index: 5;
}

.smenu:before {
  position: absolute;
  top: 0.78947rem;
  right: 0.15789rem;
  bottom: 0.78947rem;
  left: 2.63158rem;
  -webkit-box-shadow: 0 0 1.31579rem #a9bacf;
          box-shadow: 0 0 1.31579rem #a9bacf;
  content: '';
  z-index: -1;
}

.smenu__title {
  position: relative;
  font-size: 1.21053rem;
  font-weight: 700;
  line-height: 1.08696;
  color: #fff;
  background-color: #103f79;
  padding: 1.42105rem 1.57895rem;
}

@media (max-width: 1023px) {
  .smenu__title {
    padding-left: 3.15789rem;
  }
}

.smenu__title:before, .smenu__title:after {
  position: absolute;
  top: 1.94737rem;
  left: 1.57895rem;
  height: 0.63158rem;
  width: 0.84211rem;
  margin-top: -0.31579rem;
  background: url("../img/icon-chevrons.png") center top no-repeat;
  background-size: 100% auto;
  content: '';
  display: none;
}

@media (max-width: 1023px) {
  .smenu__title:before, .smenu__title:after {
    display: block;
  }
}

.smenu__title:before {
  -webkit-transition: all 0.2s, visibility 0s;
  -o-transition: all 0.2s, visibility 0s;
  transition: all 0.2s, visibility 0s;
}

.smenu__title:after {
  background-position: center bottom;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.smenu__title.active:before {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.smenu__title.active:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.smenu__title.active .xitem__summary {
  max-height: 7.89474rem;
  opacity: 1;
}

.smenu__content {
  background-color: #fff;
  font-size: 1.10526rem;
  line-height: 1.28571;
}

@media (min-width: 1024px) {
  .smenu__content {
    overflow: visible !important;
    max-height: none !important;
  }
}

@media (max-width: 1023px) {
  .smenu__content {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
  }
}

.smenu__content-inner {
  padding: 0.15789rem 0;
}

.smenu__content a {
  display: block;
  text-decoration: none;
  padding: 0.42105rem 1.57895rem;
}

.smenu__content li {
  border-top: 1px solid #eaeaea;
}

.smenu__content li:first-child {
  border-top: none;
}

.smenu__content li.active a {
  background-color: #6dafe0;
  color: #fff;
}

.fcontact {
  position: relative;
  margin-top: 2.63158rem;
}

.fcontact:first-child {
  margin-top: 0;
}

.fcontact__sep {
  margin-top: -1.84211rem;
  margin-bottom: 2.89474rem;
}

.fcontact__sep-inner {
  border-top: 1px solid #aabbcf;
}

@media (max-width: 1023px) {
  .fcontact .container {
    max-width: 53.89474rem;
  }
}

@media (max-width: 1023px) {
  .form--contact {
    max-width: 26.84211rem;
  }
}

.form--contact textarea.textfield {
  height: 6.21053rem;
}

@media (max-width: 1023px) {
  .form--contact .cols {
    display: block;
  }
}

@media (max-width: 1023px) {
  .form--contact .col {
    display: block;
    margin-top: 0.94737rem;
  }
  .form--contact .col .col:first-child {
    margin-top: 0;
  }
}

.form--contact-separate {
  margin-top: 0.78947rem;
  padding-bottom: 0.26316rem;
}

@media (max-width: 1023px) {
  .form--contact-separate {
    max-width: none;
  }
}

.form--q {
  margin-top: 1.57895rem;
}

.form--q:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .form--q {
    max-width: none;
  }
}

.form--q textarea.textfield {
  height: 8.94737rem;
}

.form--q .form__item--message textarea.textfield {
  height: 10.94737rem;
}

.form--q .cols-wrapper--checkbox-horz {
  padding-top: 0.52632rem;
}

@media (max-width: 1023px) {
  .form--q .cols-wrapper--checkbox-horz .col {
    margin-top: 0.63158rem;
  }
}

@media (max-width: 1023px) {
  .form--q .cols-wrapper--checkbox-horz .checkboxes {
    padding-right: 0.57895rem;
    -webkit-column-count: 2;
            column-count: 2;
    padding-left: 5.78947rem;
  }
}

.form--q .form__item--section {
  padding-top: 2.36842rem;
}

@media (max-width: 1023px) {
  .form--q .form__item--section {
    padding-top: 0;
  }
}

@media (max-width: 1023px) {
  .form--q .checkboxes--justify {
    padding-left: 4.73684rem;
  }
}

.form--q .checkboxes--indent {
  padding-right: 0.57895rem;
}

@media (max-width: 1023px) {
  .form--q .checkboxes--indent {
    -webkit-column-count: 2;
            column-count: 2;
    padding-left: 5.78947rem;
  }
}

@media (max-width: 1023px) {
  .form--q .checkboxes--radio {
    -webkit-column-count: 1;
            column-count: 1;
  }
}

.iblocks-wrapper {
  margin-top: 2.63158rem;
  background: #103f79;
  overflow: hidden;
}

.iblocks-wrapper:first-child {
  margin-top: 0;
}

.iblocks {
  position: relative;
  font-size: 0;
  background-color: #103f79;
}

.iblocks:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .iblocks {
    margin-left: -1.63158rem;
    margin-right: -1.63158rem;
  }
}

.iblocks:before {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  left: -1px;
  border: 1px solid transparent;
  content: '';
}

@media (max-width: 1023px) {
  .iblocks:before {
    border-color: #fff;
  }
}

.iblocks__inner {
  float: left;
  width: 100%;
  margin: -1px 0;
  z-index: 1;
}

.iblock {
  float: right;
  display: inline-block;
  vertical-align: top;
  width: 11.11111%;
  font-size: 1.10526rem;
}

[dir='ltr'] .iblock {
  font-size: 1rem;
}

@media (max-width: 1023px) {
  .iblock {
    width: 33.33333%;
  }
}

.iblock__inner {
  position: relative;
  display: block;
  text-decoration: none;
  padding: 0 1.05263rem 100%;
  background-color: #103f79;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

.iblock__inner:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid transparent;
  content: '';
}

@media (max-width: 1023px) {
  .iblock__inner:before {
    border-color: #fff;
    left: -1px;
    top: -1px;
  }
}

.iblock__inner:hover {
  color: #fff;
}

.iblock__container {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  line-height: 15.78947rem;
  margin-top: -8.15789rem;
}

.iblock__middle {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding: 0 0.52632rem;
  line-height: 1.28571;
  min-height: 7.47368rem;
}

@media (max-width: 1023px) {
  .iblock__middle {
    min-height: 8.10526rem;
  }
}

.iblock__icon {
  height: 3.36842rem;
  width: 3.36842rem;
  margin-top: 0.15789rem;
  margin-left: auto;
  margin-right: auto;
  background: url("../img/icons-hp.png") -526.26316rem center no-repeat;
  background-size: auto 2.42105rem;
}

@media (max-width: 1023px) {
  .iblock__icon {
    height: 3.89474rem;
    width: 3.89474rem;
    background-size: auto 3.89474rem;
  }
}

@media (max-width: 1023px) {
  .iblock__title {
    margin-top: 1.31579rem;
  }
}

.iblock--stomach .iblock__inner:hover {
  background-color: #6e92ac;
}

.iblock--stomach .iblock__icon {
  background-position-x: -67.26316rem;
}

@media (max-width: 1023px) {
  .iblock--stomach .iblock__icon {
    background-position-x: -109.05263rem;
  }
}

.iblock--operation .iblock__inner:hover {
  background-color: #966eac;
}

.iblock--operation .iblock__icon {
  background-position-x: -58.78947rem;
}

@media (max-width: 1023px) {
  .iblock--operation .iblock__icon {
    background-position-x: -95.31579rem;
  }
}

.iblock--tomography .iblock__inner:hover {
  background-color: #79cbbf;
}

.iblock--tomography .iblock__icon {
  background-position-x: -50.26316rem;
}

@media (max-width: 1023px) {
  .iblock--tomography .iblock__icon {
    background-position-x: -81.68421rem;
  }
}

.iblock--nurse .iblock__inner:hover {
  background-color: #74bf6c;
}

.iblock--nurse .iblock__icon {
  background-position-x: -41.78947rem;
}

@media (max-width: 1023px) {
  .iblock--nurse .iblock__icon {
    background-position-x: -68rem;
  }
}

.iblock--doctor .iblock__inner:hover {
  background-color: #d05e5e;
}

.iblock--doctor .iblock__icon {
  background-position-x: -33.26316rem;
}

@media (max-width: 1023px) {
  .iblock--doctor .iblock__icon {
    background-position-x: -54.26316rem;
  }
}

.iblock--monitor .iblock__inner:hover {
  background-color: #beb525;
}

.iblock--monitor .iblock__icon {
  background-position-x: -24.73684rem;
}

@media (max-width: 1023px) {
  .iblock--monitor .iblock__icon {
    background-position-x: -40.57895rem;
  }
}

.iblock--reins .iblock__inner:hover {
  background-color: #ef5229;
}

.iblock--reins .iblock__icon {
  background-position-x: -16.21053rem;
}

@media (max-width: 1023px) {
  .iblock--reins .iblock__icon {
    width: 4.47368rem;
    background-position-x: -26.63158rem;
  }
}

.iblock--massage .iblock__inner:hover {
  background-color: #3ab07a;
}

.iblock--massage .iblock__icon {
  background-position-x: -7.73684rem;
}

@media (max-width: 1023px) {
  .iblock--massage .iblock__icon {
    background-position-x: -13.15789rem;
  }
}

.iblock--doc-tick .iblock__inner:hover {
  background-color: #466a9a;
}

.iblock--doc-tick .iblock__icon {
  background-position-x: 0.78947rem;
}

@media (max-width: 1023px) {
  .iblock--doc-tick .iblock__icon {
    background-position-x: -0.63158rem;
  }
}

.imblocks-wrapper {
  margin-top: 2.36842rem;
}

.imblocks-wrapper:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .imblocks-wrapper {
    margin-top: 2.63158rem;
  }
}

.imblocks {
  font-size: 0;
  margin-left: -0.52632rem;
  margin-right: -0.52632rem;
  margin-top: 2.36842rem;
}

.imblocks:first-child {
  margin-top: 0;
}

.imblocks:after {
  content: "";
  display: table;
  clear: both;
}

.imblocks__inner {
  float: left;
  width: 100%;
  margin: -0.52632rem 0;
}

.imblock {
  display: inline-block;
  vertical-align: top;
  width: 20%;
  font-size: 1.10526rem;
  line-height: 1.28571;
  padding: 0.52632rem;
}

[dir='ltr'] .imblock {
  font-size: 1rem;
}

@media (max-width: 1023px) {
  .imblock {
    width: 50%;
  }
}

.imblock__inner {
  position: relative;
  display: block;
  padding-bottom: 81%;
  text-decoration: none;
  background: #103f79;
  color: #fff;
}

.imblock__inner:hover {
  color: #fff;
}

.imblock__inner:hover .imblock__summary {
  max-height: 6.84211rem;
  opacity: 1;
}

[dir='ltr'] .imblock__inner:hover .imblock__summary {
  max-height: 7.63158rem;
}

.imblock__inner:hover .imblock__content:before {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.imblock__inner:hover .imblock__content:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.imblock__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: center top no-repeat;
  background-size: 100% auto;
}

.imblock__image img {
  display: none;
}

.imblock__content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 5.52632rem;
  padding: 0.78947rem 1.31579rem 0.52632rem;
  background-color: #103f79;
}

.imblock__content:before, .imblock__content:after {
  position: absolute;
  top: 1.84211rem;
  left: 0.78947rem;
  height: 0.84211rem;
  width: 1.15789rem;
  background: url("../img/icon-square-triangles.png") center bottom no-repeat;
  background-size: 100% auto;
  content: '';
}

[dir='ltr'] .imblock__content:before, [dir='ltr'] .imblock__content:after {
  left: auto;
  right: 0.78947rem;
}

.imblock__content:before {
  -webkit-transition: all 0.2s, visibility 0s;
  -o-transition: all 0.2s, visibility 0s;
  transition: all 0.2s, visibility 0s;
}

.imblock__content:after {
  background-position: center top;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.imblock__title {
  font-size: 1.21053rem;
  font-weight: 700;
  line-height: 1.13043;
  min-height: 2.63158rem;
}

.imblock__summary {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.imblock__summary-inner {
  padding-top: 0.52632rem;
  padding-bottom: 0.78947rem;
}

.xitems {
  font-size: 0;
  margin-left: -0.78947rem;
  margin-right: -0.78947rem;
  margin-top: 2.36842rem;
}

.xitems:first-child {
  margin-top: 0;
}

.xitems:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .xitems {
    margin-left: -1.57895rem;
    margin-right: -1.57895rem;
  }
}

.xitems__inner {
  float: left;
  width: 100%;
  margin: -0.78947rem 0;
}

@media (max-width: 1023px) {
  .xitems__inner {
    margin-top: -0.52632rem;
    margin-bottom: -0.52632rem;
  }
}

@media (max-width: 1023px) {
  .xitems__inner {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.xitem {
  display: inline-block;
  vertical-align: bottom;
  width: 33.33333%;
  font-size: 0.94737rem;
  line-height: 1.27778;
  padding: 0.78947rem;
  cursor: default;
}

@media (max-width: 1023px) {
  .xitem {
    display: block;
    border-top: 1px solid #fff;
    width: 100%;
    padding: 0;
    font-size: 1.26316rem;
  }
}

.xitem__inner {
  position: relative;
  display: block;
  text-decoration: none;
  padding: 1.05263rem 3.68421rem 1.21053rem 3.15789rem;
  background: rgba(16, 63, 121, 0.9);
  color: #fff;
}

@media (max-width: 1023px) {
  .xitem__inner {
    padding: 1.36842rem 5.78947rem 1.57895rem 4.21053rem;
    background: #103f79;
  }
}

[dir='ltr'] .xitem__inner {
  padding-left: 3.68421rem;
  padding-right: 3.15789rem;
}

@media (max-width: 1023px) {
  [dir='ltr'] .xitem__inner {
    padding-left: 5.78947rem;
    padding-right: 4.21053rem;
  }
}

.xitem__inner:before, .xitem__inner:after {
  position: absolute;
  top: 1.84211rem;
  left: 1.57895rem;
  height: 0.63158rem;
  width: 0.84211rem;
  margin-top: -0.31579rem;
  background: url("../img/icon-chevrons.png") center top no-repeat;
  background-size: 100% auto;
  content: '';
}

[dir='ltr'] .xitem__inner:before, [dir='ltr'] .xitem__inner:after {
  left: auto;
  right: 1.57895rem;
}

@media (max-width: 1023px) {
  .xitem__inner:before, .xitem__inner:after {
    top: 2.31579rem;
    height: 0.94737rem;
    width: 1.26316rem;
    margin-top: -0.47368rem;
  }
}

.xitem__inner:before {
  -webkit-transition: all 0.2s, visibility 0s;
  -o-transition: all 0.2s, visibility 0s;
  transition: all 0.2s, visibility 0s;
}

.xitem__inner:after {
  background-position: center bottom;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.xitem__inner:hover {
  color: #fff;
}

.xitem__inner:hover:before {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.xitem__inner:hover:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.xitem__inner:hover .xitem__summary {
  max-height: 7.89474rem;
  opacity: 1;
}

.xitem__inner.focused {
  color: #fff;
}

.xitem__inner.focused:before {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.xitem__inner.focused:after {
  opacity: 1;
  visibility: visible;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.xitem__inner.focused .xitem__summary {
  max-height: 7.89474rem;
  opacity: 1;
}

.xitem__icon {
  position: absolute;
  top: 0.52632rem;
  right: 1.05263rem;
  background: right top no-repeat;
  background-size: auto 100%;
  height: 1.63158rem;
  width: 2.10526rem;
}

[dir='ltr'] .xitem__icon {
  left: 1.05263rem;
  right: auto;
  background-position: left top;
}

@media (max-width: 1023px) {
  .xitem__icon {
    top: 1.15789rem;
    height: 2.21053rem;
    width: 2.89474rem;
  }
}

.xitem__title {
  font-size: 1.21053rem;
  font-weight: 700;
  line-height: 1.13043;
}

@media (max-width: 1023px) {
  .xitem__title {
    font-size: 1.68421rem;
  }
}

.xitem__summary {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.xitem__summary-inner {
  padding-top: 1.05263rem;
  padding-bottom: 0.52632rem;
}

@media (max-width: 1023px) {
  .xitem__summary-inner {
    padding-top: 1.57895rem;
  }
}

.xitem--doc .xitem__icon {
  background-image: url("../img/icon-doc-media.png");
}

.xitem--user .xitem__icon {
  background-image: url("../img/icon-checkboxes-user.png");
}

.xitem--heart .xitem__icon {
  background-image: url("../img/icon-kardio.png");
}

.xitem a {
  text-decoration: none;
}

.ifblocks {
  font-size: 0;
  margin-left: -0.31579rem;
  margin-right: -0.31579rem;
  margin-top: 1.31579rem;
}

.ifblocks:first-child {
  margin-top: 0;
}

.ifblocks:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .ifblocks {
    margin-left: -0.52632rem;
    margin-right: -0.52632rem;
  }
}

.ifblocks__inner {
  float: left;
  width: 100%;
  margin: -1.31579rem 0;
}

.ifblock {
  display: inline-block;
  vertical-align: top;
  width: 33.33333%;
  font-size: 1.10526rem;
  line-height: 1.28571;
  padding: 1.31579rem 0.31579rem;
}

@media (max-width: 1023px) {
  .ifblock {
    width: 50%;
    padding-left: 0.52632rem;
    padding-right: 0.52632rem;
  }
}

.ifblock__inner {
  position: relative;
  display: block;
  text-decoration: none;
  text-align: center;
  color: #103f79;
}

.ifblock__inner:hover {
  text-decoration: none;
  color: #103f79;
}

.ifblock__inner:hover .ifblock__top {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.ifblock__top {
  color: #fff;
  background-color: #103f79;
  padding: 0.52632rem;
  -webkit-box-shadow: 0 0.47368rem 0.52632rem rgba(0, 0, 0, 0.37);
          box-shadow: 0 0.47368rem 0.52632rem rgba(0, 0, 0, 0.37);
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.ifblock__icon {
  height: 6.31579rem;
  width: 6.31579rem;
  margin-left: auto;
  margin-right: auto;
  background: url("../img/icons-hp.png") -526.26316rem center no-repeat;
  background-size: auto 5.42105rem;
}

.ifblock__title {
  font-size: 1.42105rem;
  font-weight: 700;
  line-height: 1;
  min-height: 2em;
}

@media (max-width: 1023px) {
  .ifblock__title {
    font-size: 1.21053rem;
    margin-top: 0.26316rem;
  }
  .ifblock__title:first-child {
    margin-top: 0;
  }
}

.ifblock__title br {
  display: none;
}

.ifblock__summary {
  margin-top: 0.78947rem;
}

.ifblock__summary:first-child {
  margin-top: 0;
}

.ifblock--stomach .ifblock__top {
  background-color: #6e92ac;
}

.ifblock--stomach .ifblock__icon {
  background-position-x: -151.31579rem;
}

.ifblock--operation .ifblock__top {
  background-color: #966eac;
}

.ifblock--operation .ifblock__icon {
  background-position-x: -132.31579rem;
}

.ifblock--tomography .ifblock__top {
  background-color: #79cbbf;
}

.ifblock--tomography .ifblock__icon {
  background-position-x: -113.15789rem;
}

.ifblock--nurse .ifblock__top {
  background-color: #74bf6c;
}

.ifblock--nurse .ifblock__icon {
  background-position-x: -94.21053rem;
}

.ifblock--doctor .ifblock__top {
  background-color: #d05e5e;
}

.ifblock--doctor .ifblock__icon {
  background-position-x: -75.10526rem;
}

.ifblock--monitor .ifblock__top {
  background-color: #beb525;
}

.ifblock--monitor .ifblock__icon {
  background-position-x: -56.05263rem;
}

.ifblock--reins .ifblock__top {
  background-color: #ef5229;
}

.ifblock--reins .ifblock__icon {
  background-position-x: -37rem;
}

.ifblock--massage .ifblock__top {
  background-color: #3ab07a;
}

.ifblock--massage .ifblock__icon {
  background-position-x: -17.89474rem;
}

.ifblock--doc-tick .ifblock__top {
  background-color: #466a9a;
}

.ifblock--doc-tick .ifblock__icon {
  background-position-x: 1.15789rem;
}

.ablocks {
  font-size: 0;
  margin-left: -0.31579rem;
  margin-right: -0.31579rem;
  margin-top: 1.31579rem;
}

.ablocks:first-child {
  margin-top: 0;
}

.ablocks:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .ablocks {
    margin-left: -0.52632rem;
    margin-right: -0.52632rem;
  }
}

.ablocks__inner {
  float: left;
  width: 100%;
  margin: -1.05263rem 0;
}

.ablock {
  display: inline-block;
  vertical-align: top;
  width: 33.33333%;
  font-size: 1.10526rem;
  line-height: 1.28571;
  padding: 1.05263rem 0.31579rem;
}

@media (max-width: 1023px) {
  .ablock {
    width: 50%;
    padding-left: 0.52632rem;
    padding-right: 0.52632rem;
  }
}

.ablock__inner {
  position: relative;
  display: block;
  text-decoration: none;
  padding-bottom: 87.925%;
  text-align: center;
  color: #103f79;
  overflow: hidden;
}

.ablock__inner:hover {
  text-decoration: none;
  color: #103f79;
}

.ablock__inner:hover .ablock__image:before {
  opacity: 1;
}

.ablock__inner:hover .ablock__title {
  background-color: transparent;
}

.ablock__inner:hover .ablock__summary {
  visibility: visible;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
}

.ablock__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #103f79 center bottom no-repeat;
  background-size: cover;
}

.ablock__image:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.37);
  opacity: 0;
  content: '';
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.ablock__image img {
  display: none;
}

.ablock__title {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  font-size: 1.21053rem;
  font-weight: 700;
  line-height: 1.08696;
  min-height: 2.17391em;
  color: #fff;
  background-color: #103f79;
  padding: 0.78947rem 1.57895rem;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.ablock__title br {
  display: none;
}

.ablock__summary {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
  background-color: #6dafe0;
  padding: 0.63158rem 0.78947rem;
  -webkit-transform: translateY(100%);
      -ms-transform: translateY(100%);
          transform: translateY(100%);
  visibility: hidden;
  -webkit-transition: all 0.2s, visibility 0s 0.2s;
  -o-transition: all 0.2s, visibility 0s 0.2s;
  transition: all 0.2s, visibility 0s 0.2s;
}

.persons {
  font-size: 0;
  margin-left: -0.31579rem;
  margin-right: -0.31579rem;
  margin-top: 0.78947rem;
}

.persons:first-child {
  margin-top: 0;
}

.persons:after {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 1023px) {
  .persons {
    margin-top: 1.05263rem;
  }
}

.persons__inner {
  float: left;
  width: 100%;
  margin: -1.05263rem 0;
}

@media (max-width: 1023px) {
  .persons__container {
    max-width: 26.31579rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.person {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  font-size: 1rem;
  line-height: 1.31579;
  padding: 1.05263rem 0.31579rem;
}

@media (max-width: 1023px) {
  .person {
    width: 50%;
  }
}

.person__inner {
  position: relative;
  display: block;
  text-decoration: none;
  text-align: center;
  color: #103f79;
}

.person__image {
  position: relative;
  overflow: hidden;
  padding-bottom: 111.61%;
  background: center center no-repeat;
  background-size: cover;
  margin-top: 1.05263rem;
}

.person__image:first-child {
  margin-top: 0;
}

.person__image:before {
  position: absolute;
  top: -2.63158rem;
  right: -3.15789rem;
  bottom: -2.63158rem;
  left: -3.15789rem;
  -webkit-box-shadow: inset 0 0 6.31579rem rgba(105, 115, 128, 0.5);
          box-shadow: inset 0 0 6.31579rem rgba(105, 115, 128, 0.5);
  border-radius: 7.89474rem;
  content: '';
}

.person__image > img {
  display: none;
}

.person__content {
  margin-top: 1.05263rem;
}

.person__content:first-child {
  margin-top: 0;
}

.contacts-cols {
  margin-top: 1.05263rem;
  padding-bottom: 0.78947rem;
  -webkit-column-count: 2;
          column-count: 2;
  -webkit-column-gap: 2.63158rem;
          column-gap: 2.63158rem;
  font-size: 0.94737rem;
  line-height: 1.55556;
  padding-left: 16.84211rem;
}

.contacts-cols:first-child {
  margin-top: 0;
}

[dir='ltr'] .contacts-cols {
  padding-left: 0;
  padding-right: 16.84211rem;
}

@media (max-width: 1023px) {
  [dir='ltr'] .contacts-cols {
    padding-right: 0;
  }
}

@media (max-width: 1023px) {
  .contacts-cols {
    padding-left: 0;
    -webkit-column-count: 1;
            column-count: 1;
    margin-top: 1.84211rem;
    padding-bottom: 0.52632rem;
  }
}

.contacts-cols p {
  margin-top: 1.84211rem;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -o-column-break-inside: avoid;
  -ms-column-break-inside: avoid;
  column-break-inside: avoid;
  break-inside: avoid-column;
}

.contacts-cols p:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .contacts-cols p {
    margin-top: 1.57895rem;
  }
}

.contacts-cols-sep {
  margin: 0 0.52632rem;
}

.contacts-cols-sep .latin {
  font-size: 100%;
}

.map {
  height: 22.63158rem;
  max-height: 100vw;
  background-color: #e2e2e2 !important;
  margin-top: 1.05263rem;
}

.map:first-child {
  margin-top: 0;
}

@media (max-width: 1023px) {
  .map {
    height: 100vw;
  }
}

a[href^="http://maps.google.com/maps"] {
  display: none !important;
}

a[href^="https://maps.google.com/maps"] {
  display: none !important;
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

/*

Common property classes

 */
.relative {
  position: relative;
}

.display-block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}

.reset-margin {
  margin: 0;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.hidden-absolute {
  position: absolute;
  top: -526.26316rem;
  left: -526.26316rem;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

@media (max-width: 767px) {
  .text-align-left-on-mobile {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .text-align-right-on-mobile {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .text-align-center-on-mobile {
    text-align: center;
  }
}

.vmiddle {
  vertical-align: middle;
}

.vbottom {
  vertical-align: bottom;
}

.vbaseline {
  vertical-align: baseline;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.nowrap {
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.nounderline {
  text-decoration: none;
}

.line-through {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.color-default {
  color: #103f79;
}

.color-active {
  color: #6dafe0;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.mw150 {
  max-width: 7.89474rem;
}

.mw340 {
  max-width: 17.89474rem;
}

.mw500 {
  max-width: 26.31579rem;
}

.minw170 {
  min-width: 8.94737rem;
}

.mt20 {
  margin-top: 1.05263rem;
}

.mb-20 {
  margin-bottom: -1.05263rem;
}

/*# sourceMappingURL=style.css.map */
